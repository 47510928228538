import React from 'react';

import StackLink from '~/components/Header/NavDropdownMenu/StackLink';
import { NAV_HIERARCHY } from '~/containers/Header/analytics';
import './MainStack.scss';

export default function MainStack({ wrapperClass = '', data = [] }) {
  const { taxon_id: taxonId = null, categories: subCats = [] } = data;

  return (
    <li className={`c-nvMainStack ${wrapperClass}`} data-nvid={taxonId}>
      <StackLink data={data} hierarchy={NAV_HIERARCHY?.main} />
      {subCats.length > 0 && (
        <ul className="c-nvMainStack__subList u-minied-list">
          {subCats.map((node) => (
            <li key={node?.id} className="c-nvMainStack__sub">
              <StackLink data={node} hierarchy={NAV_HIERARCHY?.sub} />
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}
