import React, { useState, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { noop, isEmpty } from 'lodash';

import {
  KEY_CODE_ENTER,
  NL_SUBSCRIBE_URL,
  NL_SUBSCRIBE_DEFAULT_LIST_ID
} from '~/containers/shared/constants';
import airbrake from '~/libs/airbrake';
import sharedAtoms from '~/containers/shared/states/atoms';
import Spinner from '~/components/shared/Spinner';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import { sendPost } from '~/utils/request';
import {
  TOP_LEVEL_DOMAINS,
  EMAIL_DOMAINS,
  EMAIL_REGEX
} from '~/utils/mailCheckConfig';
import { trackNLSub } from '~/utils/analytics/gtm';

export const DEFAULT_SUCCESS_FLAG = 'subscribed';

export default function SubscribeForm({
  blockName = '',
  listId = NL_SUBSCRIBE_DEFAULT_LIST_ID,
  sourceLabel = 'unknown_SubscribeForm',
  successFlag = DEFAULT_SUCCESS_FLAG,
  successMessage = null,
  onResponse = noop
}) {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [successResponse, setSuccessResponse] = useState(null);

  const isClient = useRecoilValue(sharedAtoms.isClient);

  const handleFocus = useCallback(() => setSuccessResponse(null), []);

  const handleChange = useCallback((e) => {
    setEmail(defaultForUndefinedOrNull(e.target.value, '').trim());
  }, []);

  const handleResolved = useCallback((msg) => onResponse(msg), [onResponse]);

  const subscribe = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: { success, message, data: res = {} }
      } = await sendPost(
        NL_SUBSCRIBE_URL,
        {
          list_id: listId,
          email,
          source_label: sourceLabel
        },
        { cache: false }
      );
      if (success) {
        handleResolved(successFlag);
        setSuccessResponse(res?.message);
        trackNLSub(sourceLabel, res?.message);
      } else {
        handleResolved(message);
        setAlert(message);
        trackNLSub(sourceLabel, message);
      }
    } catch (error) {
      handleResolved(error);
      airbrake.notify({ error });
      trackNLSub(sourceLabel, error);
    } finally {
      setLoading(false);
    }
  }, [email, handleResolved, listId, sourceLabel, successFlag]);

  const handleSubmit = useCallback(() => {
    const isInvalid = !EMAIL_REGEX.test(email);

    if (isInvalid) {
      const nextAlert = isEmpty(email)
        ? 'Please enter an email address.'
        : 'Please enter a valid email address.';

      setAlert(nextAlert);
      handleResolved(nextAlert);
    } else {
      setAlert(null);
      subscribe();
    }
  }, [email, handleResolved, subscribe]);

  const suggestEmail = useCallback(() => {
    if (!isClient || !window?.Mailcheck) return null;

    let result = null;
    window.Mailcheck.run({
      email,
      topLevelDomains: TOP_LEVEL_DOMAINS,
      domains: EMAIL_DOMAINS,
      suggested: (suggestion) => {
        result = suggestion.full;
      }
    });
    return result;
  }, [isClient, email]);

  const handleKeyUp = useCallback(
    (ev) => {
      if (ev.keyCode === KEY_CODE_ENTER) handleSubmit();

      const suggestion = suggestEmail();
      const applySuggestion = () => {
        setEmail(suggestion);
        setAlert(null);
      };

      if (suggestion) {
        setAlert(
          <>
            {'Did you mean '}
            <span
              className="u-t-lined u-p-pointer"
              role="button"
              tabIndex={-1}
              onClick={applySuggestion}
            >
              {suggestion}?
            </span>
          </>
        );
      } else {
        setAlert(null);
      }
    },
    [handleSubmit, suggestEmail]
  );

  return (
    <div className={blockName}>
      {successResponse ? (
        <div className={`${blockName}__response u-t-white`}>
          {successMessage}
        </div>
      ) : (
        <div className={`${blockName}__form`}>
          <div className={`${blockName}__field`}>
            <input
              required
              type="email"
              name="email"
              autoComplete="email"
              className={`${blockName}__input o-input o-fd o-fd-fade`}
              placeholder="Your email address"
              onFocus={handleFocus}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              readOnly={loading}
              value={email}
            />
            <button
              type="button"
              onClick={handleSubmit}
              className={`${blockName}__submit o-btn o-fd o-fd-secondary`}
              disabled={loading}
            >
              {loading ? <Spinner preset="white" /> : 'Get Deals!'}
            </button>
          </div>

          {alert && (
            <span
              className={`${blockName}__alert u-block u-t-white u-t-small u-t-italic`}
            >
              {alert}
            </span>
          )}
        </div>
      )}
    </div>
  );
}
