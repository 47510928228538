const POST_MASONRY = 'post_masonry';
const POST_PUBLIC = 'post_public';
const POST_PRIVATE = 'post_private';
const ARTICLE_PUBLIC = 'article_public';
const ARTICLE_PRIVATE = 'article_private';
const SAVE_PUBLIC = 'save_public';
const SAVE_PRIVATE = 'save_private';
const LIKE_PUBLIC = 'like_public';
const LIKE_PRIVATE = 'like_private';
const CONTEST_PUBLIC = 'contest_public';
const FOLLOWER_COMMON = 'follower_common';
const FOLLOWING_COMMON = 'following_common';

export {
  POST_MASONRY,
  POST_PUBLIC,
  POST_PRIVATE,
  ARTICLE_PUBLIC,
  ARTICLE_PRIVATE,
  SAVE_PUBLIC,
  SAVE_PRIVATE,
  LIKE_PUBLIC,
  LIKE_PRIVATE,
  CONTEST_PUBLIC,
  FOLLOWER_COMMON,
  FOLLOWING_COMMON
};
