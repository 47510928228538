import RailsVars from '~/apps/railsVariables.js.erb';
import { ACCOUNT_PATHS, USER_PATHS } from '~/containers/Account/constants';

const {
  shopping,
  orders,
  orderDetail,
  credits,
  rewards,
  particulars,
  referrals,
  security,
  notifications,
  inbox
} = ACCOUNT_PATHS;

const {
  home,
  pictures,
  housewarm,
  saves,
  wishlist,
  likes,
  follower,
  followee
} = USER_PATHS;

const USER_ROOT_LABEL = 'My Profile';
const SHOPPING_ROOT_LABEL = 'My Shopping';
const SETTINGS_ROOT_LABEL = 'My Settings';

const ACCT_HOME = {
  title: home.title,
  path: home.path,
  route: home.route,
  iconClass: 'ic-site-user',
  disabled: true
};

const ACCT_PICTURES = {
  title: pictures.title,
  path: pictures.path,
  route: pictures.route,
  iconClass: 'ic-idea-postfeed',
  disabled: false
};

const ACCT_HOUSEWARM = {
  title: housewarm.title,
  path: housewarm.path,
  route: housewarm.route,
  iconClass: 'ic-site-home',
  disabled: true
};

const ACCT_SAVES = {
  title: saves.title,
  path: saves.path,
  route: saves.route,
  iconClass: 'ic-site-bookmark',
  disabled: false
};

const ACCT_WISHLIST = {
  title: wishlist.title,
  path: wishlist.path,
  route: wishlist.route,
  iconClass: 'ic-site-heart',
  disabled: false
};

const ACCT_LIKES = {
  title: likes.title,
  path: likes.path,
  route: likes.route,
  iconClass: 'ic-site-heart',
  disabled: false
};

const ACCT_FOLLOWER = {
  title: follower.title,
  path: follower.path,
  route: follower.route,
  iconClass: 'ic-site-user',
  hidden: true
};

const ACCT_FOLLOWEE = {
  title: followee.title,
  path: followee.path,
  route: followee.route,
  iconClass: 'ic-site-user',
  hidden: true
};

const ACCT_INBOX = {
  title: inbox.title,
  path: inbox.path,
  route: inbox.route,
  iconClass: 'ic-site-bell',
  hidden: true
};

// --------------------
// Shopping Tab
// --------------------

const ACCT_SHOPPING = {
  title: shopping.title,
  path: shopping.path,
  route: shopping.route,
  iconClass: 'ic-site-cart',
  disabled: false
};

const ACCT_ORDERS = {
  title: orders.title,
  path: orders.path,
  route: orders.route,
  iconClass: 'ic-site-cart',
  disabled: false
};

const ACCT_ORDER_DETAIL = {
  title: orderDetail.title,
  path: orderDetail.path,
  route: orderDetail.route,
  iconClass: 'ic-site-cart',
  disabled: false
};

const ACCT_CREDITS = {
  title: credits.title,
  path: credits.path,
  route: credits.route,
  iconClass: 'ic-site-credits',
  disabled: false
};

const ACCT_REWARDS = {
  title: rewards.title,
  path: rewards.path,
  route: rewards.route,
  iconClass: 'ic-site-popper',
  disabled: false
};

// --------------------
// Settings Tab
// --------------------

const ACCT_PARTICULARS = {
  title: particulars.title,
  path: particulars.path,
  route: particulars.route,
  iconClass: 'ic-site-settings',
  disabled: false
};

const ACCT_REFERRALS = {
  title: referrals.title,
  path: referrals.path,
  route: referrals.route,
  iconClass: 'ic-site-referral',
  disabled: !RailsVars.REFERRAL_MODULE_ACTIVATE
};

const ACCT_SECURITY = {
  title: security.title,
  path: security.path,
  route: security.route,
  iconClass: 'ic-site-settings',
  disabled: false
};

const ACCT_NOTIFICATIONS = {
  title: notifications.title,
  path: notifications.path,
  route: notifications.route,
  iconClass: 'ic-site-settings',
  disabled: false
};

export {
  USER_ROOT_LABEL,
  SHOPPING_ROOT_LABEL,
  SETTINGS_ROOT_LABEL,
  ACCT_HOME,
  ACCT_PICTURES,
  ACCT_HOUSEWARM,
  ACCT_SAVES,
  ACCT_WISHLIST,
  ACCT_SHOPPING,
  ACCT_ORDERS,
  ACCT_ORDER_DETAIL,
  ACCT_CREDITS,
  ACCT_REWARDS,
  ACCT_PARTICULARS,
  ACCT_REFERRALS,
  ACCT_SECURITY,
  ACCT_LIKES,
  ACCT_FOLLOWER,
  ACCT_FOLLOWEE,
  ACCT_NOTIFICATIONS,
  ACCT_INBOX
};
