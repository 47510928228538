import axios from 'axios';

import RailsVars from '~/apps/railsVariables.js.erb';

const instance = axios.create({
  headers: {
    Authorization: `Bearer ${globalThis.CURRENT_USER_DATA?.auth_data?.access_token}`
  },
  baseURL: `${RailsVars.CONTENT_MEDIA_API_URL}/media`,
  'content-type': 'multipart/form-data;'
});

export const cancelSource = axios.CancelToken.source();

export const sendPost = async (path, formData, options = {}) =>
  await instance.post(path, formData, options);
