const toggleZdWidget = (shouldShow, force, sttOffset) => {
  if (typeof window !== 'object') return;

  if (globalThis?.HV) {
    globalThis?.HV?.Tracking?.GTM?.zdToggle(shouldShow, force, sttOffset);
  }
};

const allowZdWidget = (bool) => {
  if (typeof window !== 'object') return;

  if (globalThis?.HV) {
    globalThis.HV.ALLOW_ZD_WIDGET = bool;
  }
};

export { toggleZdWidget, allowZdWidget };
