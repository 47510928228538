import { useMemo, useCallback, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { isEmpty } from 'lodash';
import axios from 'axios';

import airbrake from '~/libs/airbrake';
import { SEARCH_SUGGESTIONS_API } from '~/containers/Header/constants';
import { SUGGEST_SEARCH_TYPES } from '~/containers/SearchResult/constants';
import atoms from '~/containers/Header/states/atoms';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import { SUCCESS_CODE, sendGet } from '~/utils/requestAPI';

export default function useSearchSuggestions() {
  const searchQuery = useRecoilValue(atoms.navSearchQuery);
  const klevuNavSearchResults = useRecoilValue(atoms.klevuNavSearchResults);
  const [searchSuggestions, setSearchSuggestions] = useRecoilState(
    atoms.searchSuggestions
  );

  const cleanQuery = useMemo(() => searchQuery.trim(), [searchQuery]);

  const suggestionList = useMemo(() => {
    const kvSearchResults = defaultForUndefinedOrNull(
      klevuNavSearchResults?.records,
      []
    );

    return (isEmpty(cleanQuery) && !isEmpty(searchSuggestions)) ||
      isEmpty(kvSearchResults)
      ? searchSuggestions.map((opt) => ({
          id: opt?.position.toString(),
          name: opt?.label,
          url: opt?.custom_link,
          source: opt?.source_tag,
          search_type:
            SUGGEST_SEARCH_TYPES[opt?.custom_link ? 'hv_link' : 'hv_term'],
          prefix: opt?.prefix
        }))
      : kvSearchResults.slice(0, 8).map((opt) => ({
          ...opt,
          search_type: SUGGEST_SEARCH_TYPES.klevu
        }));
  }, [cleanQuery, klevuNavSearchResults?.records, searchSuggestions]);

  const searchSuggestionsRequest = useCallback(
    async (source) => {
      try {
        const response = await sendGet(
          SEARCH_SUGGESTIONS_API,
          {},
          { cache: false, cancelToken: source.token }
        );
        const { status, data: { data: suggestions = [] } = {} } =
          response || {};
        if (status === SUCCESS_CODE) {
          setSearchSuggestions(suggestions);
        } else if (status > 0) {
          airbrake.notify({
            error: 'Search suggestions not available',
            params: { info: response }
          });
        }
      } catch (error) {
        airbrake.notify({ error });
      }
    },
    [setSearchSuggestions]
  );

  useEffect(() => {
    const cancelSource = axios.CancelToken;
    const source = cancelSource.source();

    searchSuggestionsRequest(source);

    return () => {
      source.cancel('cancel searchSuggestionsRequest effect');
    };
  }, [searchSuggestionsRequest]);

  return suggestionList;
}
