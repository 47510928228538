import React, { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isEmpty } from 'lodash';

import { ACCOUNT_PATHS, USER_PATHS } from '~/containers/Account/constants';
import { AUTH_MODE, AUTH_CONTENT } from '~/containers/ModalsManager/constants';
import atoms from '~/containers/shared/states/atoms';
import modalManagerAtoms from '~/containers/ModalsManager/states/atoms';
import DecoratedName from '~/components/shared/Decorated/Name';
import NavIcon from '~/components/Header/NavIcon';
import Avatar from '~/components/Header/Avatar';
import NavBaseOptionsList from '~/components/Header/NavBaseOptions/List';
import NavAccordionMenu from '~/components/Header/NavAccordionMenu';
import BurgerHead from '~/components/Header/NavBurgerMenu/BurgerHead';
import BurgerAppBadge from '~/components/Header/NavBurgerMenu/BurgerAppBadge';
import BurgerLinks from '~/components/Header/NavBurgerMenu/BurgerLinks';
import BurgerSocials from '~/components/Header/NavBurgerMenu/BurgerSocials';
import { hitLocations } from '~/utils/analytics/tracking';
import './NavBurgerMenu.scss';

export default function NavBurgerMenu() {
  const loggedUser = useRecoilValue(atoms.currentCustomer);
  const authConfigUpdate = useSetRecoilState(
    modalManagerAtoms.authConfigUpdate
  );

  const handleRefer = useCallback(
    () =>
      authConfigUpdate({
        authMode: AUTH_MODE?.LOGIN,
        authContent: AUTH_CONTENT?.REFERRAL,
        location: hitLocations?.burger_nav_referral
      }),
    [authConfigUpdate]
  );

  const handleAuthSignup = useCallback(
    () =>
      authConfigUpdate({
        authMode: AUTH_MODE?.SIGNUP,
        authContent: AUTH_CONTENT?.GENERAL,
        location: hitLocations?.burger_nav_signin
      }),
    [authConfigUpdate]
  );

  const handleAuthLogin = useCallback(
    () =>
      authConfigUpdate({
        authMode: AUTH_MODE?.LOGIN,
        authContent: AUTH_CONTENT?.GENERAL,
        location: hitLocations?.burger_nav_signin
      }),
    [authConfigUpdate]
  );

  return (
    <nav className="c-nvBurger">
      <div className="c-nvBurger__head u-t-white">
        <span className="c-nvBurger__head-shade u-animated-all" />
        <BurgerHead />
      </div>
      <div className="c-nvBurger__list">
        <NavBaseOptionsList>
          <NavAccordionMenu />
        </NavBaseOptionsList>
      </div>
      <div className="c-nvBurger__other">
        {isEmpty(loggedUser) ? (
          <div
            className="c-nvBurger__action u-t-white u-t-nolined u-t-nooutline is-still u-p-pointer"
            role="button"
            tabIndex={-1}
            onClick={handleRefer}
          >
            <span className="c-nvBurger__action-label">Get $30!</span>
          </div>
        ) : (
          <a
            className="c-nvBurger__action u-t-white u-t-nolined u-t-nooutline is-still u-p-pointer"
            href={ACCOUNT_PATHS.referrals.path}
          >
            <span className="c-nvBurger__action-label">Get $30!</span>
          </a>
        )}
        <hr className="c-nvBurger__hr" />
        {isEmpty(loggedUser) ? (
          <div className="c-nvBurger__action u-t-white u-t-nolined u-t-nooutline is-still u-p-pointer">
            <span
              className="c-nvBurger__action-icon"
              role="button"
              tabIndex={-1}
              onClick={handleAuthLogin}
            >
              <Avatar />
            </span>
            <span className="c-nvBurger__action-gap" />
            <span className="c-nvBurger__action-label">
              <span role="button" tabIndex={-1} onClick={handleAuthSignup}>
                New Account
              </span>
              {' / '}
              <span role="button" tabIndex={-1} onClick={handleAuthLogin}>
                Login
              </span>
            </span>
          </div>
        ) : (
          <a
            className="c-nvBurger__action u-t-white u-t-nolined u-t-nooutline is-still u-p-pointer"
            href={USER_PATHS?.home?.path}
          >
            <span className="c-nvBurger__action-icon">
              <Avatar user={loggedUser} />
            </span>
            <span className="c-nvBurger__action-gap" />
            <span className="c-nvBurger__action-label">
              <DecoratedName
                user={loggedUser}
                flagClass="c-nvBurger__pro"
                decorate
              />
            </span>
            <span className="c-nvBurger__action-gap" />
            <span className="c-nvBurger__action-icon">
              <NavIcon iconClass=" ic-site-settings" />
            </span>
          </a>
        )}
        <hr className="c-nvBurger__hr" />
        <div className="c-nvBurger__gap is-md" />
        <div className="c-nvBurger__badge">
          <BurgerAppBadge />
        </div>
        <div className="c-nvBurger__gap is-lg" />
        <BurgerLinks />
        <div className="c-nvBurger__gap is-lg" />
        <BurgerSocials />
        <div className="c-nvBurger__gap is-lg" />
      </div>
    </nav>
  );
}
