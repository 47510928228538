/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSetRecoilState } from 'recoil';
import { compact } from 'lodash';
import { buildURL } from 'react-imgix';
import classNames from 'classnames';

import {
  ALLOW_FILE_FORMATS,
  ALLOW_FILE_ATTRIBS,
  MAX_FILE_SIZE
} from '~/containers/Blog/constants';
import { CUSTOMER_INFO_FIELD_KEYS } from '~/containers/Account/constants';
import sharedSelectors from '~/containers/shared/states/selectors';
import { squareAr, commonParams } from '~/utils/imgix';
import { formatBytes } from '~/utils/helper';
import { sendPost } from '~/utils/requestMediaApi';
import airbrake from '~/libs/airbrake';
import './AvatarField.scss';

const imgixParams = {
  ...commonParams,
  ar: squareAr,
  fit: 'crop',
  w: 300
};

const FOLDER = 'avatars';

export default function AvatarField({
  initialFiles = [],
  customPrompt = null
}) {
  const setAccountForm = useSetRecoilState(
    sharedSelectors.updatePrivateProfile
  );
  const [files, setFiles] = useState([]);
  const [uploadPercent, setUploadPercent] = useState(null);

  const imageRequirements = useMemo(() => {
    const { value = 0, unit = 'mb' } = formatBytes(MAX_FILE_SIZE);
    return (
      <>
        {`Accepted: ${ALLOW_FILE_FORMATS.join(', ')}, `}
        <span className="u-t-nowrap">{`max. ${value}${unit}`}</span>
      </>
    );
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        formData.append('folder', FOLDER);
        const response = await sendPost('/upload', formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadPercent(percentCompleted);
          }
        });
        if (response?.data?.url) {
          setAccountForm({ image_url: response?.data?.url });
          setFiles(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file)
              })
            )
          );
        }
      } catch (e) {
        airbrake.notify({ e });
      } finally {
        setUploadPercent(null);
      }
    },
    [setAccountForm]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: ALLOW_FILE_ATTRIBS,
      multiple: false,
      maxSize: MAX_FILE_SIZE,
      onDrop
    });

  const removeImage = useCallback(() => {
    setAccountForm((f) => ({ ...f, image_url: '' }));
    setFiles([]);
  }, [setAccountForm]);

  const dropzoneClassnames = useCallback(
    (modifier = '') =>
      classNames('c-acfdAvatar u-animate-all', {
        [modifier]: !!modifier,
        'is-focused': isFocused,
        'is-accepted': isDragAccept,
        'is-rejected': isDragReject,
        'u-p-pointer': !files.length
      }),
    [files.length, isDragAccept, isDragReject, isFocused]
  );

  const srcUrl = useCallback(
    (image) =>
      typeof image === 'string' ? buildURL(image, imgixParams) : image?.preview,
    []
  );

  useEffect(() => {
    if (initialFiles) setFiles(compact(initialFiles));
  }, [initialFiles]);

  if (files.length) {
    return (
      <div className={dropzoneClassnames('is-preview')}>
        <div className="c-acfdAvatar__preview">
          {files.map((image) => (
            <img
              key={
                typeof image === 'string'
                  ? image
                  : `${image?.name}_${image?.lastModified}`
              }
              className="c-acfdAvatar__img u-block"
              alt="Profile"
              src={srcUrl(image)}
            />
          ))}
        </div>

        <span
          className="c-acfdAvatar__remove u-t-error u-p-pointer u-animate-all"
          role="button"
          tabIndex={-1}
          onClick={removeImage}
        >
          <span className="c-acfdAvatar__remove-icon ic-bef ic-bold ic-site-bin" />
        </span>

        {uploadPercent && uploadPercent < 100 && (
          <span className="c-acfdAvatar__prog u-b-white">
            <span
              className="c-acfdAvatar__prog-bar u-b-primary u-inline-block u-animate-all"
              style={{ width: `${uploadPercent}%` }}
            />
          </span>
        )}
      </div>
    );
  }

  return (
    <div className={dropzoneClassnames('is-prompt')} {...getRootProps()}>
      <div className="c-acfdAvatar__prompt">
        <span
          className="c-acfdAvatar__prompt-icon ic-bef ic-site-plus"
          aria-hidden
        />
        {!!customPrompt && (
          <div className="c-acfdAvatar__prompt-custom u-t-large">
            {customPrompt}
          </div>
        )}
        <div className="c-acfdAvatar__prompt-help u-t-greymid u-t-smaller">
          {imageRequirements}
        </div>
      </div>
      <input
        {...getInputProps({ id: CUSTOMER_INFO_FIELD_KEYS.AVATAR.fieldName })}
      />
    </div>
  );
}
