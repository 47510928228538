import isDesktop from './is/desktop';
import isMobile from './is/mobile';

export default function processHandlersByDeviceContext(handler) {
  const hasDesktopEvent = handler.context?.includes('onDesktop');
  const hasMobileEvent = handler.context?.includes('onMobile');
  const isDefault = !hasDesktopEvent && !hasMobileEvent;

  if (isDefault) {
    handler.handler();
  }

  if (hasDesktopEvent && isDesktop()) {
    handler.handler();
  }

  if (hasMobileEvent && isMobile()) {
    handler.handler();
  }
}
