// FIXME: we will delete this file when we convert all LLQ to SPA
//  and call API from Server API, not call from LLQ

import axios from 'axios';
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer
} from 'axios-extensions';

let instance = null;

if (globalThis.document && globalThis.document.documentMode <= 10) {
  instance = axios.create({
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
} else {
  instance = axios.create({
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Cache-Control': 'no-cache'
    },
    adapter: throttleAdapterEnhancer(
      cacheAdapterEnhancer(axios.defaults.adapter)
    )
  });
}

export const errorHandler = (error) => {
  let res;
  const buildKey = (name) => `REQUEST_AXIOS_ERROR_${name}`;
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    res = buildKey(error.response.status);
    globalThis[res] = { error: error.response };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    res = buildKey(error.request.status);
    globalThis[res] = { error: error.request };
  } else {
    // Something happened in setting up the request that triggered an Error
    res = `REQUEST_AXIOS_ERROR: ${error.message}`;
    globalThis.REQUEST_AXIOS_ERROR = { error };
  }
  return res;
};

export const cancelSource = axios.CancelToken.source();

export const sendGet = async (path, options = {}) => {
  try {
    return await instance.get(path, options);
  } catch (error) {
    if (!axios.isCancel(error)) {
      errorHandler(error);
    }
  }
};

export const sendPost = async (path, data, options = {}) => {
  try {
    return await instance.post(path, { ...data }, options);
  } catch (error) {
    if (!axios.isCancel(error)) {
      errorHandler(error);
    }
  }
};
