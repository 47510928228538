import keyMirror from 'keymirror';

import {
  PICTURES_INDEX_NAME,
  HOUSEWARM_INDEX_ITEM
} from '~/containers/Blog/constants';

const atomKeys = keyMirror({
  ORDERS: null,
  MEMBER_TIER: null,
  ORDER_DETAIL: null,
  REDEEM_ITEMS: null,
  WISHLIST_INFO: null,
  ORDER_PAGINATION: null,
  CREDIT_HISTORIES: null,
  POINTS_HISTORIES: null,
  REVIEWABLE_ITEMS: null,
  WISHLIST_PRODUCTS: null,
  REFERRALS_HISTORIES: null
});

const selectorKeys = keyMirror({
  ORDER_PAGE_COUNT: null,
  REMOVE_WISHLIST_PRODUCT: null,
  FOLLOW_ITEMS_UPDATE: null,
  FOLLOW_ITEM_UPDATE_BY_ID: null
});

const ACCT_WISHLIST_GRID = { m: 2, t: 3, d: 4, place: 12 };
const ACCT_USER_SAVES_GRID = { m: 2, t: 3, d: 4, place: 12 };
const ACCT_USER_LIKES_GRID = { m: 2, t: 3, d: 4, place: 12 };
const ACCT_USER_POSTS_GRID = { m: 2, t: 3, d: 4, place: 12 };
const ACCT_USER_FOLLOWER_GRID = { m: 1, t: 1, d: 2, place: 12 };
const ACCT_USER_FOLLOWING_GRID = { m: 1, t: 1, d: 2, place: 12 };
const ACCT_USER_ARTICLES_GRID = { m: 2, t: 3, d: 4, place: 12 };
const ACCT_REWARDS_GRID = { m: 2, t: 2, d: 4, place: 8 };

const USER_SUB = '/user';
const ACCOUNT_SUB = '/account';
const USER_ID_KEY = ':auth_user_id';
const TRACKING_ORDER = 'tracking-order';

const ORDER_CONTENT_TYPE = 'order';

const USER_DATA = globalThis?.CURRENT_USER_DATA
  ? globalThis?.CURRENT_USER_DATA
  : globalThis?.PUBLIC_PROFILE_DATA;

const USER_PATHS = {
  pathway: 'user',
  home: {
    title: 'Home',
    route: USER_ID_KEY,
    path: `${USER_SUB}/${USER_DATA?.auth_user_id}`
  },
  pictures: {
    title: PICTURES_INDEX_NAME,
    route: `${USER_ID_KEY}/pictures`,
    path: `${USER_SUB}/${USER_DATA?.auth_user_id}/pictures`
  },
  housewarm: {
    title: HOUSEWARM_INDEX_ITEM,
    route: `${USER_ID_KEY}/housewarming`,
    path: `${USER_SUB}/${USER_DATA?.auth_user_id}/housewarming`
  },
  saves: {
    title: 'Saves',
    route: `${USER_ID_KEY}/saves`,
    path: `${USER_SUB}/${USER_DATA?.auth_user_id}/saves`
  },
  wishlist: {
    title: 'Wishlist',
    route: `${USER_ID_KEY}/wishlist`,
    path: `${USER_SUB}/${USER_DATA?.auth_user_id}/wishlist`
  },
  likes: {
    title: 'Likes',
    route: `${USER_ID_KEY}/likes`,
    path: `${USER_SUB}/${USER_DATA?.auth_user_id}/likes`
  },
  follower: {
    title: 'Followers',
    route: `${USER_ID_KEY}/followers`,
    path: `${USER_SUB}/${USER_DATA?.auth_user_id}/followers`
  },
  followee: {
    title: 'Following',
    route: `${USER_ID_KEY}/following`,
    path: `${USER_SUB}/${USER_DATA?.auth_user_id}/following`
  }
};

const ACCOUNT_PATHS = {
  pathway: 'account',
  shopping: {
    title: 'Shopping',
    route: 'shopping',
    path: `${ACCOUNT_SUB}/shopping`
  },
  orders: {
    title: 'Orders',
    route: 'orders',
    path: `${ACCOUNT_SUB}/orders`
  },
  orderDetail: {
    title: 'Order Detail',
    route: 'order/:id',
    path: `${ACCOUNT_SUB}/order`
  },
  orderTracking: {
    title: 'Track My Order',
    route: `${TRACKING_ORDER}/:order_slug_id`,
    path: `/${TRACKING_ORDER}`
  },
  credits: {
    title: 'Credits',
    route: 'credits',
    path: `${ACCOUNT_SUB}/credits`
  },
  rewards: {
    title: 'Rewards',
    route: 'rewards',
    path: `${ACCOUNT_SUB}/rewards`
  },
  particulars: {
    title: 'User Info',
    route: 'particulars',
    path: `${ACCOUNT_SUB}/particulars`
  },
  referrals: {
    title: 'Referrals',
    route: 'referrals',
    path: `${ACCOUNT_SUB}/referrals`
  },
  security: {
    title: 'Security',
    route: 'security',
    path: `${ACCOUNT_SUB}/security`
  },
  notifications: {
    title: 'Notifications',
    route: `${ACCOUNT_SUB}/notifications`,
    path: `${ACCOUNT_SUB}/notifications`
  },
  inbox: {
    title: 'Notifications',
    route: `${ACCOUNT_SUB}/notifications/inbox`,
    path: `${ACCOUNT_SUB}/notifications/inbox`
  }
};

const CREDIT_APPLY_GIFT_CARD_CODE_API = '/credits/apply_gift_card_code';
const CREDIT_HISTORIES_API = '/credit_histories';
const REFERRALS_API = '/referrals';
const LOYALTY_POINT_HISTORIES_API = '/customers/loyalty_point_histories';

const UPDATE_WISHLIST_PRIVACY_API = '/wishlist/update_wishlist_privacy';
const WISHLIST_API = '/wishlist';
const WISHLIST_SHARED_API = '/wishlist/shared_wishlist';

const ORDERS_API = '/orders';
const CUSTOMER_INVOICE_API = '/customer_invoice';
const TRACKING_ORDER_API = '/tracking-order';

const UPDATE_PROFILE_API = '/customers/update_info';

const NICKNAME_VALIDATION_API = 'customers/nickname_validators';

const HELMET_CONTEXT = {};

const FOLLOWER = 'FOLLOWER';
const followerKeys = {
  SEARCH_PARAMS: `${FOLLOWER}_SEARCH_PARAMS`,
  FILTER_PARAMS: `${FOLLOWER}_FILTER_PARAMS`,
  SORT_PARAMS: `${FOLLOWER}_SORT_PARAMS`,
  PAGE_PARAMS: `${FOLLOWER}_PAGE_PARAMS`,
  SEARCH_PARAMS_DEFAULT: `${FOLLOWER}_SEARCH_PARAMS_DEFAULT`,
  FILTER_PARAMS_DEFAULT: `${FOLLOWER}_FILTER_PARAMS_DEFAULT`,
  SORT_PARAMS_DEFAULT: `${FOLLOWER}_SORT_PARAMS_DEFAULT`,
  PAGE_PARAMS_DEFAULT: `${FOLLOWER}_PAGE_PARAMS_DEFAULT`,
  IS_PAGINATING: `${FOLLOWER}_IS_PAGINATING`,
  PAGE_INFO: `${FOLLOWER}_PAGE_INFO`,
  ITEMS: `${FOLLOWER}_ITEMS`
};

const FOLLOWING = 'FOLLOWING';
const followingKeys = {
  SEARCH_PARAMS: `${FOLLOWING}_SEARCH_PARAMS`,
  FILTER_PARAMS: `${FOLLOWING}_FILTER_PARAMS`,
  SORT_PARAMS: `${FOLLOWING}_SORT_PARAMS`,
  PAGE_PARAMS: `${FOLLOWING}_PAGE_PARAMS`,
  SEARCH_PARAMS_DEFAULT: `${FOLLOWING}_SEARCH_PARAMS_DEFAULT`,
  FILTER_PARAMS_DEFAULT: `${FOLLOWING}_FILTER_PARAMS_DEFAULT`,
  SORT_PARAMS_DEFAULT: `${FOLLOWING}_SORT_PARAMS_DEFAULT`,
  PAGE_PARAMS_DEFAULT: `${FOLLOWING}_PAGE_PARAMS_DEFAULT`,
  IS_PAGINATING: `${FOLLOWING}_IS_PAGINATING`,
  PAGE_INFO: `${FOLLOWING}_PAGE_INFO`,
  ITEMS: `${FOLLOWING}_ITEMS`
};

const followerSortOptions = [
  {
    key: 'created_at',
    label: 'Most Recent',
    heading: 'Most Recent'
  }
];

const followerSortOrder = keyMirror({
  asc: null,
  desc: null
});

const followingSortOptions = [
  {
    key: 'created_at',
    label: 'Most Recent',
    heading: 'Most Recent'
  }
];

const followingSortOrder = keyMirror({
  asc: null,
  desc: null
});

const USERS_API = '/users';
const PROFILE_INFO_API = (userId) => `${USERS_API}/${userId}`;
const FOLLOWER_API = (userId) => `${USERS_API}/${userId}/followers`;
const FOLLOWEE_API = (userId) => `${USERS_API}/${userId}/followees`;
const ANON_CUSTOMER_FOLLOW_PROFILE_ID = 'ANON_CUSTOMER_FOLLOW_PROFILE_ID';
const ANON_CUSTOMER_FOLLOW_PROFILE_ACTION =
  'ANON_CUSTOMER_FOLLOW_PROFILE_ACTION';
const CACHED_FOLLOW_PROFILE = 'CACHED_FOLLOW_PROFILE';
const DELAY_REFRESH = 500;

const NOTIFICATION = 'NOTIFICATION';
const NOTIFICATION_LIST = `${NOTIFICATION}_LIST`;
const NOTIFICATION_API = '/users/notifications';
const NOTIFICATION_SETTINGS_API = '/users/notifications_settings';
const READ_ALL_NOTIFICATION_API = `${NOTIFICATION_API}/read_all`;
const USER_INBOX_API = '/users/inbox';
const SHOPPING_SUMMARY_API = '/customers/shopping_summary';

const notificationListKeys = {
  SEARCH_PARAMS: `${NOTIFICATION_LIST}_SEARCH_PARAMS`,
  FILTER_PARAMS: `${NOTIFICATION_LIST}_FILTER_PARAMS`,
  SORT_PARAMS: `${NOTIFICATION_LIST}_SORT_PARAMS`,
  PAGE_PARAMS: `${NOTIFICATION_LIST}_PAGE_PARAMS`,
  SEARCH_PARAMS_DEFAULT: `${NOTIFICATION_LIST}_SEARCH_PARAMS_DEFAULT`,
  FILTER_PARAMS_DEFAULT: `${NOTIFICATION_LIST}_FILTER_PARAMS_DEFAULT`,
  SORT_PARAMS_DEFAULT: `${NOTIFICATION_LIST}_SORT_PARAMS_DEFAULT`,
  PAGE_PARAMS_DEFAULT: `${NOTIFICATION_LIST}_PAGE_PARAMS_DEFAULT`,
  IS_PAGINATING: `${NOTIFICATION_LIST}_IS_PAGINATING`,
  PAGE_INFO: `${NOTIFICATION_LIST}_PAGE_INFO`,
  NOTIFICATION_LIST: `${NOTIFICATION_LIST}_ITEMS`,
  NOTIFICATION_LIST_STATUS: `${NOTIFICATION_LIST}_ITEMS_STATUS`,
  NOTIFICATION_LIST_UPDATE: `${NOTIFICATION_LIST}_ITEMS_UPDATE`,
  NOTIFICATION_LIST_TOTAL_PAGE: `${NOTIFICATION_LIST}_TOTAL_PAGE`,
  NOTIFICATION_LIST_PAGINATING_QUERY: `${NOTIFICATION_LIST}__PAGINATING_QUERY`,
  INBOX_SUMMARY: `${NOTIFICATION_LIST}_INBOX_SUMMARY`,
  UPDATE_INBOX_SUMMARY_UNREAD_COUNT: `${NOTIFICATION_LIST}_UPDATE_INBOX_SUMMARY_UNREAD_COUNT`
};

const INNER_HTML_MODAL_BLOCKNAME = 'c-pagePop';

const GENDER_OPTIONS = [
  { value: 'female', label: 'Female' },
  { value: 'male', label: 'Male' },
  { value: 'others', label: 'Others' },
  { value: 'rather_not_say', label: 'Rather not say' }
];

const AVATAR = {
  id: 'my_account_avatar',
  fieldName: 'image_url',
  autoComplete: 'avatar'
};

const EMAIL = {
  id: 'my_account_email',
  fieldName: 'email',
  autoComplete: 'username'
};

const FIRST_NAME = {
  id: 'my_account_first_name',
  fieldName: 'first_name',
  autoComplete: 'given-name'
};

const LAST_NAME = {
  id: 'my_account_last_name',
  fieldName: 'last_name',
  autoComplete: 'family-name'
};

const BIRTHDAY_DAY = {
  id: 'my_account_birthday_day',
  fieldName: 'bDay.day',
  autoComplete: 'bday-day'
};

const BIRTHDAY_MONTH = {
  id: 'my_account_birthday_month',
  fieldName: 'bDay.month',
  autoComplete: 'bday-month'
};

const BIRTHDAY_YEAR = {
  id: 'my_account_birthday_year',
  fieldName: 'bDay.year',
  autoComplete: 'bday-year'
};

const GENDER = {
  id: 'my_account_gender',
  name: 'my_account_gender',
  fieldName: 'gender',
  autoComplete: 'female'
};

const HOMEPAGE = {
  id: 'my_account_homepage',
  fieldName: 'homepage'
};

const SOCIAL_INSTAGRAM_HANDLE = {
  id: 'my_account_instagram_handle',
  fieldName: 'instagram_handle'
};

const DESCRIPTION = {
  id: 'my_account_description',
  fieldName: 'description'
};

const CURRENT_PASSWORD = {
  id: 'current_password',
  fieldName: 'current_password',
  autoComplete: 'current-password'
};

const NEW_PASSWORD = {
  id: 'new_password',
  fieldName: 'new_password',
  autoComplete: 'new-password'
};

const CONFIRM_NEW_PASSWORD = {
  id: 'confirm_new_password',
  fieldName: 'confirm_new_password',
  autoComplete: 'new-password'
};

const NICKNAME = {
  id: 'my_account_nickname',
  fieldName: 'nickname'
};

const CUSTOMER_INFO_FIELD_KEYS = {
  AVATAR,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  BIRTHDAY_DAY,
  BIRTHDAY_MONTH,
  BIRTHDAY_YEAR,
  GENDER,
  HOMEPAGE,
  DESCRIPTION,
  CURRENT_PASSWORD,
  NEW_PASSWORD,
  CONFIRM_NEW_PASSWORD,
  NICKNAME,
  SOCIAL_INSTAGRAM_HANDLE
};

export {
  atomKeys,
  selectorKeys,
  ACCT_WISHLIST_GRID,
  ACCT_REWARDS_GRID,
  ACCT_USER_SAVES_GRID,
  ACCT_USER_LIKES_GRID,
  ACCT_USER_POSTS_GRID,
  ACCT_USER_ARTICLES_GRID,
  ACCT_USER_FOLLOWER_GRID,
  ACCT_USER_FOLLOWING_GRID,
  ORDER_CONTENT_TYPE,
  USER_SUB,
  USER_PATHS,
  USER_ID_KEY,
  ACCOUNT_PATHS,
  CREDIT_APPLY_GIFT_CARD_CODE_API,
  CREDIT_HISTORIES_API,
  REFERRALS_API,
  LOYALTY_POINT_HISTORIES_API,
  UPDATE_WISHLIST_PRIVACY_API,
  WISHLIST_API,
  WISHLIST_SHARED_API,
  ORDERS_API,
  CUSTOMER_INVOICE_API,
  TRACKING_ORDER_API,
  UPDATE_PROFILE_API,
  HELMET_CONTEXT,
  NICKNAME_VALIDATION_API,
  SHOPPING_SUMMARY_API,
  followerKeys,
  followerSortOptions,
  followerSortOrder,
  followingKeys,
  followingSortOptions,
  followingSortOrder,
  ANON_CUSTOMER_FOLLOW_PROFILE_ID,
  ANON_CUSTOMER_FOLLOW_PROFILE_ACTION,
  CACHED_FOLLOW_PROFILE,
  DELAY_REFRESH,
  PROFILE_INFO_API,
  FOLLOWER_API,
  FOLLOWEE_API,
  NOTIFICATION_LIST,
  NOTIFICATION_API,
  NOTIFICATION_SETTINGS_API,
  READ_ALL_NOTIFICATION_API,
  USER_INBOX_API,
  notificationListKeys,
  INNER_HTML_MODAL_BLOCKNAME,
  CUSTOMER_INFO_FIELD_KEYS,
  GENDER_OPTIONS
};
