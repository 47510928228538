import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isEmpty } from 'lodash';

import atoms from '~/containers/Header/states/atoms';
import OverflowRow from '~/components/shared/OverflowRow';
import RootBud from '~/components/Header/NavDropdownMenu/RootBud';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import './NavDropdownMenu.scss';

const NAV_DROPMENU_INTERACT_EVENT = 'nav-dropdown-use';
const LAZY_DELAY = 10000;

export default function NavDropdownMenu() {
  const navTree = useRecoilValue(atoms.navigation);
  const rootHoverId = useRecoilValue(atoms.rootHover);
  const setDropdownOpen = useSetRecoilState(atoms.dropdownOpen);

  const listRef = useRef(null);
  const [lazyReset, setLazyReset] = useState(false);
  const [touched, setTouched] = useState(false);

  const updateOpens = useCallback(
    (rootLabel) =>
      setDropdownOpen((prev) => (prev === !!rootLabel ? prev : !!rootLabel)),
    [setDropdownOpen]
  );

  useEffect(() => {
    if (!touched && rootHoverId) {
      document.body.dispatchEvent(new CustomEvent(NAV_DROPMENU_INTERACT_EVENT));
      setTouched(true);
    }
  }, [rootHoverId, touched]);

  // --------------------
  // Scroll back to start
  // when onBlur
  // --------------------

  useEffect(() => {
    let lazy = null;
    if (isEmpty(rootHoverId)) {
      lazy = setTimeout(() => setLazyReset(true), LAZY_DELAY);
    }
    return () => {
      clearTimeout(lazy);
      setLazyReset(false);
    };
  }, [rootHoverId]);

  useEffect(() => {
    if (lazyReset) {
      listRef.current.parentNode.scrollLeft = 0;
      setLazyReset(false);
    }
  }, [lazyReset]);

  return (
    <OverflowRow
      blockName="c-nvDropMenu"
      wrapperTag="nav"
      listRef={listRef}
      hasNavArrows
    >
      {defaultForUndefinedOrNull(navTree, []).map((node) => (
        <RootBud
          key={node?.id}
          wrapperClass="c-nvDropMenu__node"
          data={node}
          updateOpens={updateOpens}
        />
      ))}
    </OverflowRow>
  );
}
