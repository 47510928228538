import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';
import classNames from 'classnames';

import useNavBaseOptions from '~/hooks/Header/useNavBaseOptions';
import './List.scss';

function WrapTag({
  label = '',
  href = '#',
  isActive = false,
  isToggle = false,
  children = null
}) {
  const COMMON_CLASSNAMES = `c-nvPageList__link u-block u-t-white is-${label.toLowerCase()}`;

  if (isToggle)
    return <div className={`${COMMON_CLASSNAMES} is-toggle`}>{children}</div>;

  if (isActive)
    return (
      <div className={`${COMMON_CLASSNAMES} is-active`}>
        <span className="c-nvPageList__inner">{children}</span>
      </div>
    );

  return (
    <a className={`${COMMON_CLASSNAMES} u-t-nolined is-still`} href={href}>
      <span className="c-nvPageList__inner">{children}</span>
    </a>
  );
}

export default function NavBaseOptionsList({ children = null }) {
  const paths = useNavBaseOptions();

  return (
    <div className="c-nvPageList">
      {paths.map(
        ({
          path,
          label,
          hasNew = false,
          isActive = false,
          showChildren = false
        }) => (
          <WrapTag
            key={label}
            label={label}
            href={path}
            isActive={isActive}
            isToggle={showChildren}
          >
            {showChildren ? (
              <Accordion
                className="c-nvPageList__ac"
                allowZeroExpanded
                preExpanded={[label]}
              >
                <AccordionItem
                  key={label}
                  uuid={label}
                  className="c-nvPageList__acItem"
                >
                  <AccordionItemHeading className="c-nvPageList__acHead">
                    <AccordionItemState>
                      {({ expanded }) => (
                        <AccordionItemButton className="c-nvPageList__acBtn u-p-pointer">
                          <span className="c-nvPageList__acLabel">{label}</span>
                          <span
                            className={classNames(
                              'c-nvPageList__acArrow ic-bef ic-xbld u-t-white',
                              {
                                'ic-site-plus': !expanded,
                                'ic-site-minus is-open': expanded
                              }
                            )}
                          />
                        </AccordionItemButton>
                      )}
                    </AccordionItemState>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="c-nvPageList__acBody">
                    {children}
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            ) : (
              <>
                <span className="c-nvPageList__label">{label}</span>
                {hasNew && (
                  <span className="c-nvPageList__new u-inline-block u-t-white u-b-secondary">
                    NEW
                  </span>
                )}
              </>
            )}
          </WrapTag>
        )
      )}
    </div>
  );
}
