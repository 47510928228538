import airbrake from '~/libs/airbrake';

const ahoyEvents = {
  share: 'content_share',
  search: 'search',
  view_item_list: 'view_item_list',
  view_item: 'view_item',
  add_to_wishlist: 'add_to_wishlist',

  click_filter_sort_option: 'click_filter_sort_option',
  click_filter_topic_option: 'click_filter_topic_option',
  click_filter_modal_success: 'click_filter_modal_success',
  view_content_list: 'view_content_listing_page',
  view_content_id: 'view_content_detail_page',
  select_content_id: 'select_content_detail_page',
  click_new_post_success: 'click_new_post_success',
  like_post_on: 'like_post_on',
  like_post_off: 'like_post_off',
  save_post_on: 'save_post_on',
  save_post_off: 'save_post_off',
  save_offer_on: 'save_offer_on',
  save_offer_off: 'save_offer_off',
  view_offer_tag_popup: 'view_offer_tag_popup',
  click_offer_tag_popup: 'click_offer_tag_popup',

  content_pin_click: 'content_pin_click'
};

const ahoySend = (eventName, data) => {
  try {
    window?.ahoy?.track(eventName, data);
  } catch (error) {
    airbrake.notify({ error });
  }
};

const ahoyTrack = ({
  eventType,
  eventData = {},
  sessionData = {},
  nonInteract = false
} = {}) => {
  if (typeof window !== 'object' || !window?.ahoy) return;

  const payload = {
    event_data: {
      ...eventData,
      auth_user_id: globalThis?.CURRENT_USER_DATA?.auth_user_id
    },
    location: {
      path: window.location.pathname + window.location.search,
      name: document.querySelectorAll('head title')[0]?.innerText || ''
    },
    non_interact: nonInteract,
    sessionData
  };
  ahoySend(eventType, payload);
};

export { ahoyEvents, ahoySend, ahoyTrack };
