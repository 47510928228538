import 'delayed-scroll-restoration-polyfill';
import '~/utils/customEventsPolyfill';
import 'whatwg-fetch';
import 'dom4';

if (!globalThis.crypto) {
  // eslint-disable-next-line no-inner-declarations
  function getRandomValues(abv) {
    let l = abv.length;
    // eslint-disable-next-line no-plusplus
    while (l--) {
      // eslint-disable-next-line no-param-reassign
      abv[l] = Math.floor(Math.random() * 256);
    }
    return abv;
  }

  globalThis.crypto = { getRandomValues };
}

if (!('scrollBehavior' in document.documentElement.style)) {
  // safari does not support smooth scroll
  (async () => {
    const { default: smoothScroll } = await import(
      /* webpackChunkName: 'polyfill-modern' */
      'smoothscroll-polyfill'
    );
    smoothScroll.polyfill();
  })();
}
