import React from 'react';

import NavLogo from '~/components/Header/NavLogo';
import NavSearch from '~/components/Header/NavSearch';
import BurgerIcon from '~/components/Header/ControlRow/BurgerIcon';
import AccountIcon from '~/components/Header/ControlRow/AccountIcon';
import CartIcon from '~/components/Header/ControlRow/CartIcon';
import './ControlMobile.scss';

export default function NavControlMobile() {
  return (
    <div className="c-nvMenuMobl">
      <div className="c-nvMenuMobl__seg is-left">
        <BurgerIcon />
      </div>
      <div className="c-nvMenuMobl__seg is-mid">
        <NavLogo />
      </div>
      <div className="c-nvMenuMobl__seg is-right">
        <div className="c-nvMenuMobl__actions">
          <div className="c-nvMenuMobl__search">
            <NavSearch />
          </div>
          <AccountIcon />
          <CartIcon />
        </div>
      </div>
    </div>
  );
}
