import { useState, useCallback, useEffect } from 'react';
import { isEmpty, isEqual, debounce } from 'lodash';

const RESIZE_EVENT = 'resize';
const SCROLL_EVENT = 'scroll';
const LISTEN_DELAY = 500;

export default function useNodeHeight({ ref, defaultHeight }) {
  const [nodeHeight, setNodeHeight] = useState(defaultHeight);

  const lazyUpdateHeight = useCallback(() => {
    const bounds = isEmpty(ref?.current)
      ? { height: defaultHeight }
      : ref.current.getBoundingClientRect();
    const newHeight = bounds?.height;
    setNodeHeight((prev) => (isEqual(newHeight, prev) ? prev : newHeight));
    if (
      typeof globalThis?.HV?.Components?.HeaderHeight?.update === 'function'
    ) {
      globalThis?.HV?.Components?.HeaderHeight?.update(newHeight);
    }
  }, [defaultHeight, ref]);

  useEffect(() => {
    const onResize = debounce(() => {
      lazyUpdateHeight();
    }, LISTEN_DELAY);

    window.addEventListener(RESIZE_EVENT, onResize);
    onResize();
    return () => {
      window.removeEventListener(RESIZE_EVENT, onResize);
    };
  }, [lazyUpdateHeight]);

  useEffect(() => {
    const onScroll = debounce(() => {
      lazyUpdateHeight();
    }, LISTEN_DELAY);

    window.addEventListener(SCROLL_EVENT, onScroll);
    onScroll();
    return () => {
      window.removeEventListener(SCROLL_EVENT, onScroll);
    };
  }, [lazyUpdateHeight]);

  return nodeHeight;
}
