import { noop } from 'lodash';

import messageConstants from './messageConstants';

const ReactNativeWebView =
  typeof window === 'object'
    ? window.ReactNativeWebView
    : { postMessage: noop };

const postMessage = (action, data = undefined) =>
  ReactNativeWebView.postMessage(JSON.stringify({ action, data }));

export const navigateToLogin = () =>
  postMessage(messageConstants.NAVIGATE_TO_LOGIN);

export const showShare = (url) =>
  postMessage(messageConstants.SHARE_ACTION, url);
