/* eslint-disable consistent-return */
import isClientSide from './clientSide';

export default function isMobile() {
  if (!isClientSide()) return;

  return (
    window.matchMedia('(hover: none)').matches ||
    navigator.userAgent.toLowerCase().includes('mobile')
  );
}
