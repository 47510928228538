import {
  gtmTriggers,
  customEventGa4,
  cachedPageViewData
} from '~/utils/analytics/gtm';
import { amplitudeTrack } from '~/utils/analytics/amplitude';
import { noop } from 'lodash';

const MODAL_ACCOUNT_INFO = 'account';
const MODAL_PROFILE_INFO = 'profile';

const trackOnboardModalAction = (
  eventName = 'unknown_trackOnboardModalAction',
  mode = undefined,
  url = undefined
) => {
  const ga4Payload = { mode, url };
  customEventGa4(gtmTriggers?.userInfoRequest, eventName, ga4Payload);
  amplitudeTrack(eventName, ga4Payload);
};

const trackCalloutAction = (
  eventName = 'unknown_trackCalloutAction',
  { image, message, link, ...otherProps } = {},
  cb = noop
) => {
  const whitelistMap = {
    code: 'trigger',
    result: 'mode',
    listId: 'id',
    position: 'list_position',
    variant: 'filter',
    viewCount: 'impressions',
    acted: 'value'
  };

  const safePayload = {
    location: image,
    title: message,
    url: link,
    referrer: cachedPageViewData()?.currentPage?.path,
    cg1: cachedPageViewData()?.currentPage?.cg1,
    ...Object.keys(whitelistMap).reduce((acc, safeKey) => {
      const gaKey = whitelistMap?.[safeKey];
      acc[gaKey] = otherProps?.[safeKey];
      return acc;
    }, {})
  };

  customEventGa4(gtmTriggers?.shopCalloutModal, eventName, safePayload);
  amplitudeTrack(eventName, safePayload);
  cb();
};

export {
  MODAL_ACCOUNT_INFO,
  MODAL_PROFILE_INFO,
  trackOnboardModalAction,
  trackCalloutAction
};
