import React from 'react';

import './HomePro.scss';

export default function HomePro({ wrapperClass = '' }) {
  return (
    <svg
      className={`${wrapperClass} c-homePro u-inline-block`}
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      title="Home Professional"
    >
      <title>Home Professional</title>
      <g>
        <path
          className="c-homePro__wood"
          d="m140,5l-30,55v7.06c6.45,0,11.94-4.34,14.07-10.43.15-.44.54-.66.93-.66s.78.22.93.66c2.12,6.09,7.62,10.43,14.07,10.43s11.94-4.34,14.07-10.43c.15-.44.54-.66.93-.66s.78.22.93.66c2.12,6.09,7.62,10.43,14.07,10.43v-7.06l-30-55h0Z"
        />
        <path
          className="c-homePro__stem"
          d="m155.93,56.63c-.31-.88-1.56-.88-1.87,0-2.12,6.09-7.62,10.43-14.07,10.43s-11.94-4.34-14.07-10.43c-.31-.88-1.56-.88-1.87,0-2.12,6.09-7.62,10.43-14.07,10.43v129.94h60V67.06c-6.45,0-11.94-4.34-14.07-10.43Z"
        />
        <path
          className="c-homePro__s1"
          d="m155.73,56.3c-.47-.53-1.41-.42-1.67.33-2.12,6.09-7.62,10.43-14.07,10.43s-11.94-4.34-14.07-10.43c-.26-.75-1.2-.86-1.67-.33l-.27.7v140h32V57l-.27-.7Z"
        />
        <path
          className="c-homePro__nib"
          d="m125.63,31.34c4.27,2.33,9.16,3.66,14.37,3.66s10.1-1.33,14.37-3.66l-14.37-26.34-14.37,26.34Z"
        />
        <polygon
          className="c-homePro__s3"
          points="110 197 124 197 124 57 140 5 110 60 110 197"
        />
      </g>
      <path
        className="c-homePro__wood"
        d="m36,93.81l63.17,70.19h-63.17v-70.19M0,0v200h180L0,0h0Z"
      />
      <g className="c-homePro__s2">
        <path d="m35.08,38.97l-4.01-4.46s-.02.01-.02.02l-4.46,4.02c-1.23,1.11-1.33,3.01-.22,4.24.59.66,1.41.99,2.23.99.72,0,1.43-.25,2.01-.77l4.46-4.02s.01-.01.02-.02Z" />
        <path d="m45.86,51l-10.4,9.37c-1.23,1.11-1.33,3.01-.22,4.24.59.66,1.41.99,2.23.99.72,0,1.43-.25,2.01-.77l10.4-9.37s.01-.01.02-.02l-4.01-4.46s-.01,0-.02.01Z" />
        <path d="m64.71,71.9l-4.01-4.46s-.01,0-.02.01l-4.46,4.01c-1.23,1.11-1.33,3.01-.22,4.24.59.66,1.41.99,2.23.99.72,0,1.43-.25,2.01-.77l4.46-4.01s0,0,.01-.01Z" />
        <path d="m79.53,88.37l-4.01-4.46s0,0-.01,0l-4.46,4.01c-1.23,1.11-1.33,3.01-.22,4.24.59.66,1.41.99,2.23.99.72,0,1.43-.25,2.01-.77l4.46-4.01s0,0,0-.01Z" />
        <path d="m90.33,100.38l-10.4,9.37c-1.23,1.11-1.33,3.01-.22,4.24.59.66,1.41.99,2.23.99.71,0,1.43-.25,2.01-.77l10.4-9.37s0,0,0,0l-4.01-4.46s0,0,0,0Z" />
        <path d="m109.17,121.3l-4.01-4.46s0,0,0,0l-4.46,4.01c-1.23,1.11-1.33,3.01-.22,4.24.59.66,1.41.99,2.23.99.71,0,1.43-.25,2.01-.77l4.46-4.01s0,0,0,0Z" />
        <path d="m119.97,133.3l-4.46,4.01c-1.23,1.11-1.33,3.01-.22,4.24.59.66,1.41.99,2.23.99.71,0,1.43-.25,2.01-.77l4.46-4.01-4.01-4.46Z" />
        <path d="m124.39,159.13c-1.23,1.11-1.33,3.01-.22,4.24.59.66,1.41.99,2.23.99.71,0,1.43-.25,2.01-.77l10.4-9.36-4.01-4.46-10.4,9.37Z" />
        <path d="m149.61,166.23l-4.45,4.01c-1.23,1.11-1.33,3.01-.22,4.24.59.66,1.41.99,2.23.99.71,0,1.43-.25,2.01-.77l4.45-4.01-4.01-4.46Z" />
        <path d="m129.88,191c-1.66,0-3,1.34-3,3v6h6v-6c0-1.66-1.34-3-3-3Z" />
        <path d="m108.66,183c-1.66,0-3,1.34-3,3v14h6v-14c0-1.66-1.34-3-3-3Z" />
        <path d="m87.44,191c-1.66,0-3,1.34-3,3v6h6v-6c0-1.66-1.34-3-3-3Z" />
        <path d="m66.22,191c-1.66,0-3,1.34-3,3v6h6v-6c0-1.66-1.34-3-3-3Z" />
        <path d="m45,183c-1.66,0-3,1.34-3,3v14h6v-14c0-1.66-1.34-3-3-3Z" />
        <path d="m23.78,191c-1.66,0-3,1.34-3,3v6h6v-6c0-1.66-1.34-3-3-3Z" />
      </g>
    </svg>
  );
}
