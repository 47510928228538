import { useState, useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import DOMPurify from 'dompurify';
import { isEmpty } from 'lodash';

import { mediaQueryPreset } from '~/containers/shared/constants';
import {
  humanListCount,
  currentTimeVisible,
  defaultForUndefinedOrNull
} from '~/utils/helper';

export const FALLBACK_MESSAGE = {
  label_desk: 'Enjoy Free Shipping For Orders Over $300 →',
  label_mobl: 'Free Shipping Over $300 →'
};

export default function useMarqueeData() {
  const isMobile = useMediaQuery(mediaQueryPreset.mobile);

  const [marqInterval, setMarqInterval] = useState();
  const [marqMessages, setMarqMessages] = useState([FALLBACK_MESSAGE]);

  const currentlyVisible = useCallback(
    (props) => currentTimeVisible(props?.start, props?.end),
    []
  );

  const responsiveLabel = useCallback(
    (props) =>
      defaultForUndefinedOrNull(
        props?.[isMobile ? 'label_mobl' : 'label_desk'],
        ''
      ),
    [isMobile]
  );

  const sanitisedResponsiveInnerMessage = useCallback(
    (props) => ({
      __html: DOMPurify.sanitize(responsiveLabel(props))
    }),
    [responsiveLabel]
  );

  const cacheSafeData = useCallback(
    ({ interval, messages = [] } = {}) => {
      if (!isEmpty(interval)) setMarqInterval(interval);

      if (!isEmpty(messages))
        setMarqMessages(
          messages.filter(currentlyVisible).map((props, idx) => ({
            id: responsiveLabel(props),
            position: humanListCount(idx),
            safeInnerHtml: sanitisedResponsiveInnerMessage(props),
            link: props?.link,
            start: props?.start,
            end: props?.end,
            tooltip: props?.tooltip
          }))
        );
    },
    [currentlyVisible, responsiveLabel, sanitisedResponsiveInnerMessage]
  );

  useEffect(() => {
    const initData = isEmpty(globalThis?.MARQ_CONFIG)
      ? {
          interval: undefined,
          messages: [FALLBACK_MESSAGE]
        }
      : globalThis?.MARQ_CONFIG;
    cacheSafeData(initData);
  }, [cacheSafeData]);

  return { interval: marqInterval, messages: marqMessages };
}
