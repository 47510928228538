import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import appsFlyerIds from '~/utils/appsflyerConfig';
import './BurgerAppBadge.scss';

export default function BurgerAppBadge() {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);

  return (
    <a
      className={classNames('c-appBadge u-t-nolined u-t-nooutline is-still', {
        'is-on': hover
      })}
      href={t('appsflyer_url', { cid: appsFlyerIds.HAMBURGER_BADGE })}
      target="_blank"
      rel="noopener noreferrer nofollow"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span className="c-appBadge__wrapper u-inline-block u-t-nowrap">
        <span
          className="c-appBadge__icon ic-bef ic-logo-ios ic-solid"
          aria-hidden
        />
        <span
          className="c-appBadge__icon ic-bef ic-logo-android ic-solid"
          aria-hidden
        />
        <span className="c-appBadge__text u-inline-block">
          Download our app!
        </span>
      </span>
    </a>
  );
}
