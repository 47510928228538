import React, { useMemo } from 'react';
import { bool, string } from 'prop-types';

import './Spinner.scss';

const bits = [
  { id: 'c', cx: '40', cy: '9' },
  { id: 'd', cx: '61.92', cy: '18.08' },
  { id: 'e', cx: '71', cy: '40' },
  { id: 'f', cx: '61.92', cy: '61.92' },
  { id: 'g', cx: '40', cy: '71' },
  { id: 'h', cx: '18.08', cy: '61.92' },
  { id: 'a', cx: '9', cy: '40' },
  { id: 'b', cx: '18.08', cy: '18.08' }
];

export default function Spinner({ preset, reverse, isReverse }) {
  const bitElements = useMemo(
    () =>
      bits.map(({ id, cx, cy }) => (
        <circle
          key={id}
          className={`o-ld__bit o-ld__${id}`}
          cx={cx}
          cy={cy}
          r="7"
        />
      )),
    []
  );

  // Using this method instead of classNames library
  // because somehow classNames does not play well
  // with defaultProps string value
  const activeColorModifier = useMemo(
    () => (isReverse ? reverse : preset),
    [isReverse, preset, reverse]
  );

  return (
    <div className="o-ld">
      <svg
        className={`o-ld__svg is-${activeColorModifier}`}
        viewBox="0 0 80 80"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>HV Loader</title>
        {bitElements}
      </svg>
    </div>
  );
}

Spinner.propTypes = {
  preset: string,
  reverse: string,
  isReverse: bool
};

Spinner.defaultProps = {
  preset: 'grey',
  reverse: 'grey',
  isReverse: false
};
