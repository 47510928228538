import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';

import { NAV_MODES, trackNavAction } from '~/containers/Header/analytics';
import { ga4Events } from '~/utils/analytics/gtm';

export default function NestLevel({
  blockName = '',
  data = {},
  children = null,
  final = false,
  hierarchy
}) {
  const {
    taxon_id: taxonId = null,
    name = null,
    relative_url: relativeUrl = null,
    categories = []
  } = data;

  const linkClass = useMemo(
    () =>
      [
        `${blockName}__link`,
        'u-t-white',
        'u-t-nolined',
        'u-t-nooutline',
        'is-still',
        'u-t-nowrap'
      ].join(' '),
    [blockName]
  );

  const isEndNode = useMemo(
    () => final || !categories.length,
    [categories.length, final]
  );

  const handleClick = useCallback(
    (ev) => {
      if (!isEndNode) {
        ev.preventDefault();
      } else {
        trackNavAction(ga4Events?.nav_click_link, {
          mode: NAV_MODES?.accordion_link,
          path: relativeUrl,
          hierarchy
        });
      }
    },
    [hierarchy, isEndNode, relativeUrl]
  );

  if (isEndNode)
    return (
      <div className={`${blockName}__btn`}>
        <a
          className={`${linkClass} is-end`}
          href={relativeUrl}
          data-nvid={taxonId}
          onClick={handleClick}
        >
          {name}
        </a>
      </div>
    );

  return (
    <AccordionItem uuid={relativeUrl} className={`${blockName}__item`}>
      <AccordionItemState>
        {({ expanded }) => (
          <div
            className={classNames(`${blockName}__shade`, {
              'has-shade': expanded
            })}
          >
            <AccordionItemHeading className={`${blockName}__head`}>
              <AccordionItemButton
                className={classNames(
                  `${blockName}__btn u-p-pointer u-t-nooutline`,
                  {
                    'is-open': expanded
                  }
                )}
              >
                <a
                  className={`${linkClass} is-mid`}
                  href={relativeUrl}
                  data-nvid={taxonId}
                  onClick={handleClick}
                >
                  {name}
                </a>
                <span
                  className={classNames(
                    `${blockName}__icon ic-bef ic-xbld u-t-white`,
                    {
                      'ic-site-plus': !expanded,
                      'ic-site-minus is-open': expanded
                    }
                  )}
                />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={`${blockName}__panel`}>
              {children}
            </AccordionItemPanel>
          </div>
        )}
      </AccordionItemState>
    </AccordionItem>
  );
}
