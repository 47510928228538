import React, { useMemo } from 'react';

function FormNode({ element, modifiers = null, children = null }) {
  const classnames = useMemo(
    () =>
      [`c-acctSettings__${element}`, modifiers].filter((n) => !!n).join(' '),
    [element, modifiers]
  );
  return <div className={classnames}>{children}</div>;
}

function FormLabel({ id, modifiers = null, title = null }) {
  const classnames = useMemo(
    () => ['c-acctSettings__label', modifiers].filter((n) => !!n).join(' '),
    [modifiers]
  );
  return (
    <label htmlFor={id} className={classnames}>
      {title}
    </label>
  );
}

function FormField({ modifiers = null, children = null }) {
  const classnames = useMemo(
    () =>
      ['c-acctSettings__field c-acctField__wrap', modifiers]
        .filter((n) => !!n)
        .join(' '),
    [modifiers]
  );
  return <div className={classnames}>{children}</div>;
}

function FormRevealField({
  blockName = 'c-acctSettings',
  isRevealed = false,
  icon = false,
  onClick = () => null
}) {
  const label = useMemo(() => (isRevealed ? 'Hide' : 'Show'), [isRevealed]);

  return (
    <span
      role="button"
      tabIndex={-1}
      className={`${blockName}__toggle u-p-pointer is-${label.toLowerCase()}-next`}
      aria-label={`${label} Password`}
      title={label}
      onClick={onClick}
    >
      {icon ? (
        <span
          className={`${blockName}__toggle-icon u-animate-all ic-bef ic-bold ${
            isRevealed ? 'ic-site-watch' : 'ic-site-unwatch'
          }`}
          aria-hidden
        />
      ) : (
        <span className={`${blockName}__toggle-text u-t-smaller`}>{label}</span>
      )}
    </span>
  );
}

export { FormNode, FormLabel, FormField, FormRevealField };
