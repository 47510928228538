import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import './BurgerSocials.scss';

export default function BurgerSocials() {
  const { t } = useTranslation('marketing');

  const links = useMemo(
    () => [
      {
        icon: 'ic-socl-facebook',
        label: 'Facebook',
        path: t('marketing:social_links.facebook')
      },
      {
        icon: 'ic-socl-instagram',
        label: 'Instagram',
        path: t('marketing:social_links.instagram')
      },
      {
        icon: 'ic-socl-pinterest',
        label: 'Pinterest',
        path: t('marketing:social_links.pinterest')
      },
      {
        icon: 'ic-logo-tiktok',
        label: 'Tiktok',
        path: t('marketing:social_links.tiktok')
      },
      {
        icon: 'ic-logo-x',
        label: 'X',
        path: t('marketing:social_links.twitter')
      }
    ],
    [t]
  );

  const linkClass = useMemo(
    () =>
      [
        'c-nvSocials__link',
        'u-inline-block',
        'u-t-white',
        'u-t-nolined',
        'u-t-nooutline',
        'is-still'
      ].join(' '),
    []
  );

  return (
    <ul className="c-nvSocials u-minied-list">
      {links.map(({ icon, label, path }) => (
        <li
          key={`${icon}-${label}`}
          className="c-nvSocials__item u-inline-block"
        >
          <a
            className={linkClass}
            href={path}
            title={`Follow us on ${label}`}
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <span
              className={`c-nvSocials__icon ic-bef ic-solid ${icon}`}
              aria-hidden
            />
          </a>
        </li>
      ))}
    </ul>
  );
}
