const HIGHLIGHT_PRICE_TAG = 'highlightprice';
const HIGHLIGHT_PERCENT_TAG = 'highlightpercent';
const HIGHLIGHT_COMPARE_TAG = 'highlightcompare';
const L_SHAPED_SOFAS_TAG = 'lshapedsofas';
const MATTRESSES_TAG = 'mattresses';

const GIFT_CARD_TAG = 'egiftcard';
const PRELOVED_TAG = 'preloved';
const SHOWROOM_DISPLAY_TAG = 'showroomdisplay';
const SHOWROOM_TAG = 'showroom';
const FINALSALE_TAG = 'finalsale';
const T_14_DAYS_RETURN = '14daysreturn';
const T_100_NIGHTS_TRIAL = '100nightstrial';
const DISPOSAL_SERVICE = 'disposalservice';

const BESTSELLER_TAGLIST = ['bestsellerbadge', 'approvedmarketinglist'];
const NEW_ARRIVAL_TAGLIST = ['newarrivals', 'newfurniture', 'newfurnishings'];

export {
  HIGHLIGHT_PRICE_TAG,
  HIGHLIGHT_PERCENT_TAG,
  HIGHLIGHT_COMPARE_TAG,
  L_SHAPED_SOFAS_TAG,
  MATTRESSES_TAG,
  GIFT_CARD_TAG,
  PRELOVED_TAG,
  SHOWROOM_DISPLAY_TAG,
  SHOWROOM_TAG,
  FINALSALE_TAG,
  T_14_DAYS_RETURN,
  T_100_NIGHTS_TRIAL,
  DISPOSAL_SERVICE,
  BESTSELLER_TAGLIST,
  NEW_ARRIVAL_TAGLIST
};
