import React from 'react';
import classNames from 'classnames';

import useNavBaseOptions from '~/hooks/Header/useNavBaseOptions';
import './Links.scss';

export default function NavBaseOptionsLinks() {
  const paths = useNavBaseOptions();

  return (
    <div className="c-nvPageLinks u-inline-block">
      {paths.map(({ path, label, hasNew = false, isActive = false }) => (
        <a
          key={label}
          className={classNames(
            'c-nvPageLinks__link u-inline-block u-t-nolined is-still',
            {
              'is-active': isActive,
              [`is-${label.toLowerCase()}`]: !!label
            }
          )}
          href={path}
        >
          <span className="c-nvPageLinks__label">
            {label}
            {hasNew && (
              <span className="c-nvPageLinks__new u-inline-block u-t-white u-b-secondary">
                NEW
              </span>
            )}
          </span>
        </a>
      ))}
    </div>
  );
}
