import React from 'react';

const MANUAL_SWITCH = false;

if (process.env.NODE_ENV === 'development' && MANUAL_SWITCH) {
  // eslint-disable-next-line import/no-extraneous-dependencies, global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true
  });
}
