const TOP_LEVEL_DOMAINS = [
  'com.sg',
  'org.sg',
  'edu.sg',
  'sg',
  'com',
  'net',
  'org'
];
const EMAIL_DOMAINS = [
  'gmail.com',
  'hotmail.com',
  'hotmail.sg',
  'yahoo.com',
  'yahoo.com.sg',
  'msn.com',
  'live.com',
  'live.com.sg',
  'singnet.com.sg',
  'outlook.com',
  'outlook.sg',
  'icloud.com'
];

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export { TOP_LEVEL_DOMAINS, EMAIL_DOMAINS, EMAIL_REGEX };
