import React, { useMemo } from 'react';

import {
  ACCT_PICTURES,
  ACCT_SHOPPING,
  ACCT_WISHLIST,
  ACCT_CREDITS,
  ACCT_REFERRALS,
  ACCT_REWARDS
} from '~/components/Account/AccountMenu/config';
import useLogout from '~/hooks/shared/useLogout';
import './AccountDropdown.scss';

const profileNode = {
  ...ACCT_PICTURES,
  title: 'Account',
  iconClass: 'ic-site-user'
};

export default function AccountDropdown() {
  const navList = useMemo(
    () => [
      profileNode,
      ACCT_SHOPPING,
      ACCT_WISHLIST,
      ACCT_REWARDS,
      ACCT_CREDITS,
      ACCT_REFERRALS
    ],
    []
  );

  const logout = useLogout();

  return (
    <div className="c-nvAcctMenu">
      <ul className="c-nvAcctMenu__list u-minied-list">
        {navList.map((page) => {
          if (page.disabled) return null;
          return (
            <li key={page.title} className="c-nvAcctMenu__item u-t-nowrap">
              <a
                className="c-nvAcctMenu__link u-animate-all u-t-nolined is-still"
                href={page.path}
              >
                <span
                  className={`c-nvAcctMenu__icon ic-bef ic-bold ${page.iconClass}`}
                  aria-hidden
                />
                <span className="c-nvAcctMenu__label">{page.title}</span>
              </a>
            </li>
          );
        })}
        <li className="c-nvAcctMenu__item u-t-nowrap">
          <span
            role="button"
            tabIndex={-1}
            className="c-nvAcctMenu__link u-animate-all u-t-nolined is-still"
            onClick={logout}
          >
            <span
              className="c-nvAcctMenu__icon ic-bef ic-bold ic-site-logout"
              aria-hidden
            />
            <span className="c-nvAcctMenu__label">Logout</span>
          </span>
        </li>
      </ul>
    </div>
  );
}
