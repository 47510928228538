import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import enCommon from './locales/en/common.json';
import enContent from './locales/en/content.json';
import enValidation from './locales/en/validation.json';
import enMarketing from './locales/en/marketing.json';
import enPayment from './locales/en/payment.json';
import enProducts from './locales/en/products.json';
import enViewCheckouts from './locales/en/viewCheckouts.json';
import enViewAccounts from './locales/en/viewAccounts.json';

import msCommon from './locales/ms/common.json';
import msContent from './locales/ms/content.json';
import msValidation from './locales/ms/validation.json';
import msMarketing from './locales/ms/marketing.json';
import msPayment from './locales/ms/payment.json';
import msProducts from './locales/ms/products.json';
import msViewCheckouts from './locales/ms/viewCheckouts.json';
import msViewAccounts from './locales/ms/viewAccounts.json';

const resources = {
  en: {
    common: enCommon,
    content: enContent,
    validation: enValidation,
    marketing: enMarketing,
    payment: enPayment,
    products: enProducts,
    viewcheckouts: enViewCheckouts,
    viewaccounts: enViewAccounts
  },
  ms: {
    common: msCommon,
    content: msContent,
    validation: msValidation,
    marketing: msMarketing,
    payment: msPayment,
    products: msProducts,
    viewcheckouts: msViewCheckouts,
    viewaccounts: msViewAccounts
  }
};

const localeMap = {
  Singapore: 'en',
  Malaysia: 'ms'
};
const ourLocale = localeMap[process.env.STORE_COUNTRY];

i18n
  .use(XHR)
  // pass i18n down to react-i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: ourLocale,
    debug: process.env.RAILS_ENV !== 'production',
    load: 'languageOnly',
    fallbackLng: 'en',
    defaultNS: 'common',
    // send untranslated keys to endpoint
    saveMissing: process.env.RAILS_ENV !== 'production',
    // react already safes from xss
    interpolation: {
      escapeValue: false
    },
    react: {
      // Can be applied as local overrides
      // wait: true
      useSuspense: false
    }
  });

export default i18n;
