import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { urlMergeParams } from '~/utils/helper';
import { squareAr, commonParams } from '~/utils/imgix';
import './NavIcon.scss';

const imgixParams = {
  ...commonParams,
  ar: squareAr,
  fit: 'crop',
  w: 200
};

export default function NavIcon({
  iconClass,
  image = null,
  count = null,
  isPadded = false
}) {
  const noImage = useMemo(() => isEmpty(image), [image]);
  const finalSrc = useMemo(
    () => urlMergeParams({ rawUrl: image, params: imgixParams }),
    [image]
  );

  const iconClassNames = useMemo(
    () =>
      ['c-nvCir__symbol', 'ic-bef', 'ic-bold', 'u-inline-block', iconClass]
        .filter((n) => !!n)
        .join(' '),
    [iconClass]
  );

  if (noImage && isEmpty(iconClass)) return null;

  return (
    <div
      className={classNames('c-nvCir u-inline-block', {
        'is-padded': isPadded,
        'is-img': !noImage,
        'is-ico': noImage
      })}
    >
      {noImage ? (
        <span className="c-nvCir__well u-inline-block">
          <span className={iconClassNames} aria-hidden />
        </span>
      ) : (
        <div
          className="c-nvCir__thumb u-inline-block"
          style={{ backgroundImage: `url(${finalSrc})` }}
        />
      )}
      {count >= 1 && <span className="c-nvCir__count u-block">{count}</span>}
    </div>
  );
}
