import React, { useRef, useState, useMemo, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import {
  DEFAULT_NAV_HEIGHT,
  NAV_TOP_THRESHOLD
} from '~/containers/Header/constants';
import { mediaQueryPreset } from '~/containers/shared/constants';
import sharedAtoms from '~/containers/shared/states/atoms';
import atoms from '~/containers/Header/states/atoms';
// import TopBar from '~/components/Header/TopBar';
import NewsTicker from '~/components/shared/NewsTicker';
import ControlDesktop from '~/components/Header/ControlRow/Desktop';
import ControlMobile from '~/components/Header/ControlRow/Mobile';
import NavDropdownMenu from '~/components/Header/NavDropdownMenu';
import NavSearch from '~/components/Header/NavSearch';
import RegisterModal from '~/components/ModalAuth/Register';
import PasswordResetModal from '~/components/ModalAuth/PasswordReset';
import useMarqueeData from '~/hooks/Header/useMarqueeData';
import useScrollData from '~/hooks/shared/useScrollData';
import useNodeHeight from '~/hooks/shared/useNodeHeight';
import './view.scss';

const READY_DELAY = 200;

export default function View({ modifierClasses = '', isLoading = false }) {
  const navRef = useRef(null);
  const [ready, setReady] = useState(true);
  const isDesktop = useMediaQuery(mediaQueryPreset.desktop);
  const isClient = useRecoilValue(sharedAtoms.isClient);
  const isProductPage = useRecoilValue(sharedAtoms.isProductPage);
  const dropdownOpen = useRecoilValue(atoms.dropdownOpen);
  const searchFocus = useRecoilValue(atoms.searchFocus);

  const setNavHeight = useSetRecoilState(sharedAtoms.headerHeight);
  const { direction, position } = useScrollData(ready);

  const wrapperClasses = useMemo(
    () =>
      ['c-head', modifierClasses, isProductPage ? '' : 'is-sticky']
        .filter((n) => !!n)
        .join(' '),
    [isProductPage, modifierClasses]
  );

  const navHeight = useNodeHeight({
    ref: navRef,
    defaultHeight: DEFAULT_NAV_HEIGHT
  });

  // --------------------
  // Marquee
  // --------------------

  const { interval, messages } = useMarqueeData();

  // --------------------
  // Section Visibility
  // --------------------

  const hasTopBar = useMemo(
    () => isClient && !isEmpty(messages),
    [isClient, messages]
  );

  const showTopBar = useMemo(() => {
    if (isProductPage) return true;
    return position <= NAV_TOP_THRESHOLD / 2;
  }, [isProductPage, position]);

  const showNavDropdownMenu = useMemo(() => {
    if (!isClient || direction === 'up' || isProductPage) return true;
    return position <= NAV_TOP_THRESHOLD * 2 || dropdownOpen;
  }, [direction, dropdownOpen, isClient, isProductPage, position]);

  const showNavSearch = useMemo(() => {
    if (!isClient || direction === 'up' || isProductPage) return true;
    return position <= NAV_TOP_THRESHOLD * 1.5 || searchFocus;
  }, [direction, isClient, isProductPage, position, searchFocus]);

  const loadPaddingEms = useMemo(() => {
    // Magic numbers closely match
    // final header heights to minimise
    // 'CLS' SEO penalty
    const topBar = hasTopBar && showTopBar ? 1.035 : 0;
    const control = isDesktop ? 2 : 1.6;
    const menu = isDesktop ? 1.12 : 0;
    const search = isDesktop ? 0 : 1.76;

    return (topBar + control + menu + search).toFixed(2);
  }, [hasTopBar, isDesktop, showTopBar]);

  // --------------------
  // Scroll Management
  // --------------------

  useEffect(() => {
    setReady(false);
    setNavHeight(navHeight);

    let pending = null;
    pending = setTimeout(() => setReady(true), READY_DELAY);

    return () => {
      clearTimeout(pending);
      setReady(true);
    };
  }, [
    hasTopBar,
    showTopBar,
    showNavDropdownMenu,
    showNavSearch,
    setNavHeight,
    navHeight
  ]);

  if (isLoading)
    return (
      <div
        style={{
          textAlign: 'center',
          padding: `${loadPaddingEms}em`
        }}
      />
    );

  return (
    <>
      <RegisterModal />
      <PasswordResetModal />

      <header ref={navRef} className={wrapperClasses}>
        <div className="c-head__wrap">
          {isClient && (
            <div
              className={classNames('c-head__ticker u-animate-all', {
                'is-hidden': !showTopBar
              })}
            >
              <NewsTicker
                list={messages}
                duration={interval}
                // Animation speed needs to be updated together with
                // ~/components/Header/Marquee/Marquee.scss:16
                // speed={400}
              />
            </div>
          )}

          <div className="c-head__container">
            {/* <section className="c-head__top u-b-bg visible-xs">
              <TopBar />
            </section> */}
            <section className="c-head__controls is-notmobile">
              <ControlDesktop />
            </section>
            <section className="c-head__controls is-mobile">
              <ControlMobile />
            </section>
            <section className="c-head__menu">
              {showNavDropdownMenu && <NavDropdownMenu />}
            </section>
            <section className="c-head__search">
              {showNavSearch && (
                <div className="c-head__search-wrap">
                  <NavSearch />
                </div>
              )}
            </section>
          </div>
        </div>
        <div
          className={classNames('c-head__shade', {
            'is-on': dropdownOpen
          })}
        />
      </header>
    </>
  );
}
