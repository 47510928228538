import { useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import atoms from '~/containers/shared/states/atoms';
import { localStorageHelper } from '~/utils/storageHelper';
import { defaultForUndefinedOrNull } from '~/utils/helper';

// related to hipvan.inone.useinsider.com
// /mobile-experiment/2582
// /mobile-experiment/2581
// /custom/2580
export const KLEVU_RESPONSE_FLAG = 'KLEVU_TAXON_RESPONSE_KEY';

export default function useKlevuTaxons() {
  const setIsKlevu = useSetRecoilState(atoms.klevuResponse);

  const updateStatus = useCallback(() => {
    setIsKlevu(
      defaultForUndefinedOrNull(
        localStorageHelper.getItem(KLEVU_RESPONSE_FLAG)?.data,
        null
      )
    );
  }, [setIsKlevu]);

  useEffect(() => {
    updateStatus();
  }, [updateStatus]);
}
