import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { useCookies } from 'react-cookie';
import Imgix from 'react-imgix';
import { DateTime } from 'luxon';
import { isInteger, isEmpty, noop } from 'lodash';

import {
  CALLOUT_IMPRESSION_DELAY,
  APP_NUDGE_PAUSE_KEY,
  APP_NUDGE_COUNT_KEY
} from '~/containers/ModalsManager/constants';
import HvModal from '~/components/shared/HvModal';
import { useCalloutViewingEvents } from '~/hooks/shared/useCalloutsData';
import { commonParams } from '~/utils/imgix';
import { ga4Events } from '~/utils/analytics/gtm';
import { trackCalloutAction } from '~/containers/ModalsManager/analytics';
import './ModalAppNudge.scss';

const cssLock = `
  body {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

export default function ModalAppNudge({
  data = {},
  visible = false,
  setVisible = noop
}) {
  const {
    viewCount,
    image = '',
    title,
    link,
    target = '_blank',
    retry,
    closeInMs,
    name
  } = data;

  const [, setCookie] = useCookies([APP_NUDGE_PAUSE_KEY]);
  const [closeable, setCloseable] = useState(false);
  const [seen, setSeen] = useState(false);
  const [acted, setActed] = useState(false);

  const closeDelayMs = useMemo(
    () =>
      isInteger(closeInMs) && closeInMs >= 0
        ? closeInMs
        : CALLOUT_IMPRESSION_DELAY,
    [closeInMs]
  );

  const eventPayload = useMemo(
    () => ({
      image,
      message: title,
      link,
      variant: name,
      viewCount
    }),
    [image, title, link, name, viewCount]
  );

  const handleUnsub = useCallback(
    () =>
      setCookie(APP_NUDGE_PAUSE_KEY, 1, {
        expires: DateTime.now().plus(retry).toJSDate(),
        path: '/',
        sameSite: 'Strict'
      }),
    [retry, setCookie]
  );

  const handleClick = useCallback(() => {
    setActed(true);
    trackCalloutAction(ga4Events?.click_callout_app_nudge, eventPayload);
  }, [eventPayload]);

  const canMount = useMemo(() => !isEmpty(image) && visible, [image, visible]);

  const { trackOnClose, trackBeforeunload } = useCalloutViewingEvents({
    impressionable: canMount,
    intersecting: visible,
    countProp: APP_NUDGE_COUNT_KEY,
    closeEvent: ga4Events?.close_callout_app_nudge,
    impressionEvent: ga4Events?.view_callout_app_nudge,
    payload: eventPayload,
    seen,
    setSeen
  });

  const handleClose = useCallback(() => {
    setVisible(false);
    handleUnsub();
    trackOnClose(acted);
  }, [acted, handleUnsub, setVisible, trackOnClose]);

  useEffect(() => {
    let lazyUpdate = null;
    setCloseable(false);

    if (visible) {
      lazyUpdate = setTimeout(() => setCloseable(true), closeDelayMs);
    }

    return () => {
      clearTimeout(lazyUpdate);
      setCloseable(true);
    };
  }, [closeDelayMs, visible]);

  useEffect(() => {
    setVisible(!isEmpty(data) && !isEmpty(image));
  }, [data, image, setVisible]);

  return (
    <Beforeunload onBeforeunload={() => trackBeforeunload(acted)}>
      <HvModal
        onClose={handleClose}
        isOpen={visible}
        contentLabel="App Nudge"
        blockName="c-mdNudge"
        dialogBg="u-b-primary"
        defaultHeader={false}
      >
        {visible && <style>{cssLock}</style>}
        {closeable && (
          <span
            role="button"
            tabIndex={-1}
            className="c-mdNudge__close u-p-pointer u-t-nooutline u-t-white"
            onClick={handleClose}
          >
            <span
              className="c-mdNudge__sym ic-bef ic-site-cross ic-xbld"
              aria-hidden
            />
          </span>
        )}
        <a
          className="c-mdNudge__link u-block u-t-nolined u-t-nooutline is-still"
          href={link}
          title={title}
          onClick={handleClick}
          target={target}
          rel="noreferrer"
        >
          {!isEmpty(image) && (
            <Imgix
              className="c-mdNudge__img u-block"
              sizes="100vw"
              disableSrcSet
              src={image}
              imgixParams={{ ...commonParams, w: 900 }}
              htmlAttributes={{
                alt: title,
                draggable: false
              }}
            />
          )}
        </a>
      </HvModal>
    </Beforeunload>
  );
}
