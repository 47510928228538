const LOCAL_STORAGE_KEY = 'localStorage';
const SESSION_STORAGE_KEY = 'sessionStorage';

const changeEvent = (storeKey) => `${storeKey}-changed`;

const storageHelper = (storeKey) => ({
  setItem: (key, value) => {
    try {
      // fix the case JSON.parse(JSON.stringify(undefined)) throw error
      globalThis?.[storeKey]?.setItem(key, JSON.stringify(value ?? null));
      document.dispatchEvent(new CustomEvent(changeEvent(storeKey)));
      return true;
    } catch (error) {
      if (typeof window === 'object') throw new Error(error);
      return false;
    }
  },
  getItem: (key) => {
    try {
      return JSON.parse(globalThis?.[storeKey]?.getItem(key));
    } catch (error) {
      if (typeof window === 'object') throw new Error(error);
      return null;
    }
  },
  removeItem: (key) => {
    try {
      globalThis?.[storeKey]?.removeItem(key);
      document.dispatchEvent(new CustomEvent(changeEvent(storeKey)));
      return true;
    } catch (error) {
      if (typeof window === 'object') throw new Error(error);
      return false;
    }
  },
  removeAll: () => {
    try {
      globalThis?.[storeKey]?.clear();
      document.dispatchEvent(new CustomEvent(changeEvent(storeKey)));
      return true;
    } catch (error) {
      if (typeof window === 'object') throw new Error(error);
      return false;
    }
  }
});

const localStorageHelper = storageHelper(LOCAL_STORAGE_KEY);
const localStorageEvent = changeEvent(LOCAL_STORAGE_KEY);
const sessionStorageHelper = storageHelper(SESSION_STORAGE_KEY);
const sessionStorageEvent = changeEvent(SESSION_STORAGE_KEY);

export {
  localStorageHelper,
  localStorageEvent,
  sessionStorageHelper,
  sessionStorageEvent
};
