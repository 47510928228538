import React from 'react';
import { useSetRecoilState } from 'recoil';

import atoms from '~/containers/Header/states/atoms';
import NavIcon from '~/components/Header/NavIcon';
import './BurgerHead.scss';

export default function BurgerHead() {
  const setBurgerOpen = useSetRecoilState(atoms.burgerOpen);

  const toggleBurger = (toggle) => () => setBurgerOpen(toggle);

  return (
    <div className="c-nvBurgerHead">
      <div className="c-nvBurgerHead__fill">
        <NavIcon iconClass="ic-site-categories" />
        <span className="c-nvBurgerHead__label u-inline-block">Menu</span>
      </div>
      <div
        role="button"
        tabIndex={-1}
        className="c-nvBurgerHead__fixed u-p-pointer u-t-nooutline u-inline-block"
        onClick={toggleBurger(false)}
      >
        <NavIcon iconClass="ic-site-cross" />
      </div>
    </div>
  );
}
