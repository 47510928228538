/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { isEmpty, startCase } from 'lodash';

import {
  CUSTOMER_INFO_FIELD_KEYS,
  EMPTY_FIELD_ERROR,
  AUTH_MODE
} from '~/containers/ModalsManager/constants';
import sharedAtoms from '~/containers/shared/states/atoms';
import atoms from '~/containers/ModalsManager/states/atoms';
import HvModal from '~/components/shared/HvModal';
import FacebookAuthButton from '~/components/shared/ExternalAuth/Facebook';
import GoogleAuthButton from '~/components/shared/ExternalAuth/Google';
import AuthGreetings, {
  authAction
} from '~/components/ModalAuth/AuthGreetings';
import { FormRevealField } from '~/components/Account/ProfileUpdate/FormHelper';
import Spinner from '~/components/shared/Spinner';
import useAuthRequest from '~/hooks/shared/useAuthRequest';
import { toggleZdWidget } from '~/utils/zendeskControls';
import {
  ga4Events,
  gtmTriggers,
  customEventGa4,
  cachedPageViewData,
  pagePathQueryFragment
} from '~/utils/analytics/gtm';
import { amplitudeTrack } from '~/utils/analytics/amplitude';
import {
  sendPageViewGTM,
  sendPrevPageView
} from '~/utils/analytics/virtualPageView';
import './Register.scss';

const EMAIL_FIELD_KEY = 'customer_email';
const PASSWORD_FIELD_KEY = 'customer_password';

export default function RegisterModal() {
  const isClient = useRecoilValue(sharedAtoms.isClient);

  const [isOpen, setIsOpen] = useRecoilState(atoms.showRegisterModal);
  const setShowForgotPasswordModal = useSetRecoilState(
    atoms.showResetPasswordModal
  );
  const [authConfig, setAuthConfig] = useRecoilState(atoms.authConfig);
  const authLocation = useRecoilValue(atoms.authLocation);
  const authExtraInfo = useRecoilValue(atoms.authExtraInfo);
  const [isReveal, setIsReveal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useRecoilState(
    atoms.authErrorMessage
  );

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues: {
      current_email: '',
      current_password: '',
      newsletter_subscription: true,
      source_path: isClient ? window.location.pathname : ''
    }
  });

  const subscribeNewsletter = watch(
    CUSTOMER_INFO_FIELD_KEYS.SUBSCRIBE_NEWSLETTER.fieldName,
    true
  );

  const password = watch(CUSTOMER_INFO_FIELD_KEYS.PASSWORD.fieldName, '');

  const email = watch(CUSTOMER_INFO_FIELD_KEYS.EMAIL.fieldName, '');

  const isEmptyPassword = useMemo(() => isEmpty(password), [password]);

  const isLogIn = useMemo(
    () => authConfig?.authMode === AUTH_MODE?.LOGIN,
    [authConfig?.authMode]
  );

  const authText = useCallback((_isLogin) => authAction(_isLogin), []);

  const trackingVirtualPageView = useCallback(
    () =>
      sendPageViewGTM({
        title: `${startCase(authConfig?.authMode)} | HipVan`,
        path: `${authConfig?.authMode}?pop=true&source=${authConfig?.authContent}`,
        handle: authConfig?.authMode,
        cg1: 'account'
      }),
    [authConfig]
  );

  const toggleMode = useCallback(() => {
    trackingVirtualPageView();
    setAuthConfig((prev) => ({
      authContent: prev?.authContent,
      authMode:
        prev?.authMode === AUTH_MODE?.LOGIN
          ? AUTH_MODE?.SIGNUP
          : AUTH_MODE?.LOGIN
    }));
  }, [setAuthConfig, trackingVirtualPageView]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    toggleZdWidget(true);
    sendPrevPageView('real');
  }, [setIsOpen]);

  const handleForgotPassword = useCallback(() => {
    setShowForgotPasswordModal(true);
    handleClose();
  }, [handleClose, setShowForgotPasswordModal]);

  const handleAuth = useAuthRequest({
    mode: authConfig?.authMode,
    setIsSubmitting,
    setError: setErrorMessage,
    handleClose
  });

  const trackModalTriggerEvent = useCallback(() => {
    const ga4Payload = {
      mode: authConfig?.authMode,
      location: pagePathQueryFragment(),
      trigger: authLocation,
      id: [authExtraInfo?.type || 'unknown', authExtraInfo?.id]
        .filter((s) => !!s)
        .join('-'),
      referrer: cachedPageViewData()?.previousPage?.path
    };
    customEventGa4(
      gtmTriggers?.userAuthFlow,
      ga4Events?.view_sign_in_modal,
      ga4Payload
    );
    amplitudeTrack(ga4Events?.view_sign_in_modal, ga4Payload);
  }, [
    authConfig?.authMode,
    authExtraInfo?.id,
    authExtraInfo?.type,
    authLocation
  ]);

  const nativeToLogin = useCallback(
    (authType) => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            action: 'navigateToLogin',
            data: { type: authType, email }
          })
        );
      }
    },
    [email]
  );

  useEffect(() => {
    if (isOpen) {
      trackModalTriggerEvent();
      trackingVirtualPageView();
      if (globalThis.isMobileApp) nativeToLogin(authConfig?.authMode, email);
    }
  }, [
    authConfig,
    email,
    isOpen,
    nativeToLogin,
    trackModalTriggerEvent,
    trackingVirtualPageView
  ]);

  useEffect(() => {
    setErrorMessage('');
  }, [isDirty, authConfig?.authMode, setErrorMessage]);

  return (
    <HvModal
      onClose={handleClose}
      isOpen={isOpen}
      blockName="c-rcAuth"
      contentLabel="Signup/Login Modal"
    >
      <div className="c-rcAuth__well is-top">
        <AuthGreetings
          blockName="c-rcAuth"
          isLogin={isLogIn}
          authContent={authConfig?.authContent}
        />

        <div className="c-rcAuth__external">
          <div className="c-rcAuth__external-row">
            <FacebookAuthButton handleClose={handleClose} />
          </div>
          <div className="c-rcAuth__external-row">
            <GoogleAuthButton handleClose={handleClose} />
          </div>
        </div>

        <div className="c-rcAuth__divider u-t-greymid u-t-small u-t-bold">
          <span>or</span>
        </div>

        <div className="c-rcAuth__native">
          <form className="" onSubmit={handleSubmit(handleAuth)}>
            <div className="c-rcAuth__field is-mail">
              <input
                type="email"
                name={EMAIL_FIELD_KEY}
                id={CUSTOMER_INFO_FIELD_KEYS.EMAIL.id}
                className={classNames(
                  'c-rcAuth__input o-input o-fd o-fd-greymid u-block',
                  {
                    'is-error':
                      errors[CUSTOMER_INFO_FIELD_KEYS.EMAIL.fieldName] ||
                      !isEmpty(errorMessage)
                  }
                )}
                placeholder="Email"
                autoComplete="username"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus="autofocus"
                {...register(CUSTOMER_INFO_FIELD_KEYS.EMAIL.fieldName, {
                  required: true
                })}
              />
              {errors?.[CUSTOMER_INFO_FIELD_KEYS.EMAIL.fieldName] && (
                <div
                  className="c-rcAuth__error u-t-smaller u-t-error"
                  htmlFor={EMAIL_FIELD_KEY}
                >
                  {EMPTY_FIELD_ERROR}
                </div>
              )}
            </div>

            <div className="c-rcAuth__field is-pass">
              <div className="c-rcAuth__group">
                <input
                  type={isReveal ? 'text' : 'password'}
                  name={PASSWORD_FIELD_KEY}
                  id={CUSTOMER_INFO_FIELD_KEYS.PASSWORD.id}
                  className={classNames(
                    'c-rcAuth__input o-input o-fd o-fd-greymid u-block',
                    {
                      'is-error':
                        errors[CUSTOMER_INFO_FIELD_KEYS.PASSWORD.fieldName] ||
                        !isEmpty(errorMessage)
                    }
                  )}
                  placeholder="Password"
                  autoComplete="current-password"
                  {...register(CUSTOMER_INFO_FIELD_KEYS.PASSWORD.fieldName, {
                    required: true
                  })}
                />

                {!isEmptyPassword && (
                  <FormRevealField
                    blockName="c-rcAuth"
                    isRevealed={isReveal}
                    onClick={() => setIsReveal((cur) => !cur)}
                    icon
                  />
                )}
              </div>
              {errors?.[CUSTOMER_INFO_FIELD_KEYS.PASSWORD.fieldName] && (
                <div
                  className="c-rcAuth__error u-t-smaller u-t-error"
                  htmlFor={PASSWORD_FIELD_KEY}
                >
                  {EMPTY_FIELD_ERROR}
                </div>
              )}
            </div>

            {errorMessage && (
              <div className="c-rcAuth__error u-t-smaller u-t-error">
                {errorMessage}
              </div>
            )}

            <div className="c-rcAuth__actions">
              <button
                type="submit"
                className="c-rcAuth__cta o-btn o-fd o-fd-primary o-fd--block"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className="c-rcAuth__loading">
                    <Spinner preset="white" />
                  </div>
                ) : (
                  startCase(authText(isLogIn))
                )}
              </button>
            </div>
            {!isLogIn && (
              <div className="c-rcAuth__field is-sbsp js-auth__sbsp">
                <label
                  className="c-rcAuth__sbspCheck u-t-grey u-t-small icheck-label u-p-pointer"
                  htmlFor="newsletter_subscription"
                >
                  <div
                    className={classNames('icheckbox icheck-item', {
                      checked: subscribeNewsletter
                    })}
                  >
                    <input
                      type="checkbox"
                      name="newsletter_subscription"
                      id={CUSTOMER_INFO_FIELD_KEYS.SUBSCRIBE_NEWSLETTER.id}
                      className="icheck icheck-input"
                      {...register(
                        CUSTOMER_INFO_FIELD_KEYS.SUBSCRIBE_NEWSLETTER.fieldName
                      )}
                    />
                  </div>
                  <span className="c-rcAuth__sbspLabel">
                    Subscribe to our inspirational newsletter!
                  </span>
                </label>
              </div>
            )}
          </form>
        </div>

        <div className="c-rcAuth__closing u-t-greymid">
          {isLogIn ? (
            <div className="c-rcAuth__forgot">
              <span
                role="button"
                tabIndex={-1}
                className="c-rcAuth__forgot-txt u-t-small u-p-pointer"
                onClick={handleForgotPassword}
              >
                Forgot your password?
              </span>
            </div>
          ) : (
            <div className="c-rcAuth__tnc u-t-smaller">
              {'By signing up, you agree to our '}
              <a
                className="c-rcAuth__tnc-link u-inline-block u-t-greymid is-still u-t-lined"
                href="/pages/terms-conditions"
                target="_blank"
              >
                Terms of Service & Privacy Policy
              </a>
              .
            </div>
          )}
        </div>
      </div>

      <hr className="c-rcAuth__hr" />

      <div
        className="c-rcAuth__well is-bottom u-p-pointer"
        role="button"
        tabIndex={0}
        onClick={toggleMode}
      >
        {isLogIn ? 'New to HipVan? ' : 'Have an account? '}
        <span className="c-rcAuth__authMode u-t-primary u-t-lined">
          {authText(!isLogIn)}
        </span>
      </div>
    </HvModal>
  );
}
