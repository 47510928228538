import React, {
  Fragment,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';
import Slider from 'react-slick';
import { Beforeunload } from 'react-beforeunload';
import { useMediaQuery } from 'react-responsive';
import Imgix from 'react-imgix';
import { isEmpty, noop, merge } from 'lodash';

import {
  STICKY_PROMO_RATIO_W_MOBL,
  STICKY_PROMO_RATIO_W_DESK,
  STICKY_PROMO_DEFAULT_INTERVAL,
  STICKY_PROMO_PAUSE_KEY,
  STICKY_PROMO_COUNT_KEY
} from '~/containers/ModalsManager/constants';
import { PRIMARY_COLOR, mediaQueryPreset } from '~/containers/shared/constants';
import { useCalloutViewingEvents } from '~/hooks/shared/useCalloutsData';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import { commonParams, UrlHelper } from '~/utils/imgix';
import { sessionStorageHelper } from '~/utils/storageHelper';
import { ga4Events } from '~/utils/analytics/gtm';
import { trackCalloutAction } from '~/containers/ModalsManager/analytics';
import 'slick-carousel/slick/slick.scss';
import './ModalStickyPromo.scss';

const PARAMS_MOBL = {
  ...commonParams,
  fit: 'crop',
  ar: `${STICKY_PROMO_RATIO_W_MOBL}:1`,
  w: 900
};

const PARAMS_DESK = {
  ...commonParams,
  fit: 'crop',
  ar: `${STICKY_PROMO_RATIO_W_DESK}:1`,
  w: 2000
};

function WrapTag({
  message = {},
  bgColor = PRIMARY_COLOR,
  handleClick = noop,
  children = null
}) {
  if (isEmpty(children)) return null;

  return isEmpty(message?.link) ? (
    <div
      className="c-mdPromo__link u-animate-all"
      style={{ backgroundColor: bgColor }}
      role="button"
      tabIndex={-1}
      onClick={handleClick}
    >
      {children}
    </div>
  ) : (
    <a
      className="c-mdPromo__link u-block u-t-nolined u-t-nooutline is-still u-animate-all"
      style={{ backgroundColor: bgColor }}
      href={message?.link}
      title={message?.title}
      target={defaultForUndefinedOrNull(message?.target, '_blank')}
      onClick={handleClick}
    >
      {children}
    </a>
  );
}

// function Arrow({
//   className,
//   style,
//   onClick,
//   arrowColor,
//   direction = '',
//   iconModifier = ''
// }) {
//   return (
//     <div
//       role="button"
//       tabIndex={-1}
//       className={`${className} c-mdPromo__arrBtn u-p-pointer u-animate-all is-${direction}`}
//       style={{ ...style, color: arrowColor }}
//       onClick={onClick}
//     >
//       <span className="c-mdPromo__arrDim u-block u-animate-all" />
//       <span className="c-mdPromo__arrBox u-block">
//         <span
//           className={`c-mdPromo__arrIco ic-bef ic-site-arrow ic-dir-${direction} ${iconModifier}`}
//         />
//       </span>
//     </div>
//   );
// }

export default function ModalStickyPromo({
  data = {},
  visible = false,
  setVisible = noop,
  covered = false
}) {
  const isMobile = useMediaQuery(mediaQueryPreset.mobile);
  const isTablet = useMediaQuery(mediaQueryPreset.tablet);
  const { messages = [], interval = STICKY_PROMO_DEFAULT_INTERVAL } = data;

  const [focus, setFocus] = useState(false);
  const [seen, setSeen] = useState(false);
  const [acted, setActed] = useState(false);
  const [shown, setShown] = useState([]);
  const [activeId, setActiveId] = useState(0);

  const iconModifier = useMemo(
    () => (!isMobile && !isTablet ? 'ic-xbld' : 'ic-solid'),
    [isMobile, isTablet]
  );

  const displayList = useMemo(
    () =>
      messages
        .map((props) => {
          let pickSrc;
          if (isTablet) {
            pickSrc = defaultForUndefinedOrNull(
              props?.image_tabl,
              props?.image_desk
            );
          } else {
            pickSrc = isMobile ? props?.image_mobl : props?.image_desk;
          }
          const { result: safeSrc, params } = new UrlHelper(
            defaultForUndefinedOrNull(pickSrc, '')
          ).clean();

          return {
            ...props,
            src_plain: safeSrc,
            src_params: merge({}, isMobile ? PARAMS_MOBL : PARAMS_DESK, params)
          };
        })
        .filter((m) => !!m?.src_plain),
    [isMobile, isTablet, messages]
  );

  const activeSlideTextColor = useMemo(
    () =>
      defaultForUndefinedOrNull(displayList?.[activeId]?.color_text, 'white'),
    [activeId, displayList]
  );

  const toggleFocus = useCallback((state) => () => setFocus(state), []);

  const onChange = useCallback((nowId) => setActiveId(nowId), []);

  const fullyImpressed = useMemo(
    () => shown.length >= displayList.length,
    [displayList.length, shown.length]
  );

  const handleImpressed = useCallback(
    (slide) =>
      setShown((prev) =>
        prev.includes(slide?.position) ? prev : [...prev, slide?.position]
      ),
    []
  );

  const swiperSettings = useMemo(
    () => ({
      // prevArrow: (
      //   <Arrow
      //     direction="left"
      //     iconModifier={iconModifier}
      //     arrowColor={activeSlideTextColor}
      //   />
      // ),
      // nextArrow: (
      //   <Arrow
      //     direction="right"
      //     iconModifier={iconModifier}
      //     arrowColor={activeSlideTextColor}
      //   />
      // ),
      arrows: false,
      swipeToSlide: true,
      autoplay: true,
      autoplaySpeed: interval,
      speed: 500,
      // fade: true,
      pauseOnFocus: true,
      afterChange: onChange
    }),
    [interval, onChange]
  );

  const eventPayload = useMemo(
    () => ({
      image: displayList?.[activeId]?.src_plain,
      message: displayList?.[activeId]?.title,
      link: displayList?.[activeId]?.link,
      position: displayList?.[activeId]?.position,
      variant: displayList?.[activeId]?.name,
      viewCount: data?.viewCount
    }),
    [activeId, data?.viewCount, displayList]
  );

  const handleUnsub = useCallback(
    () => sessionStorageHelper.setItem(STICKY_PROMO_PAUSE_KEY, true),
    []
  );

  const handleClick = useCallback(() => {
    setActed(true);
    trackCalloutAction(ga4Events?.click_callout_sticky_promo, eventPayload);
  }, [eventPayload]);

  const canMount = useMemo(
    () => !isEmpty(displayList) && visible,
    [displayList, visible]
  );

  const { trackOnClose, trackBeforeunload } = useCalloutViewingEvents({
    impressionable: canMount && !fullyImpressed,
    intersecting: visible && !covered,
    countProp: STICKY_PROMO_COUNT_KEY,
    closeEvent: ga4Events?.close_callout_sticky_promo,
    impressionEvent: ga4Events?.view_callout_sticky_promo,
    payload: eventPayload,
    seen,
    setSeen,
    impressionCb: handleImpressed
  });

  const handleClose = useCallback(() => {
    setVisible(false);
    handleUnsub();
    trackOnClose(acted);
  }, [acted, handleUnsub, setVisible, trackOnClose]);

  useEffect(() => {
    setVisible(!isEmpty(data) && !isEmpty(displayList));
  }, [data, displayList, setVisible]);

  return (
    <Beforeunload onBeforeunload={() => trackBeforeunload(acted)}>
      <div
        className={`c-mdPromo u-b-bg ${focus ? 'is-focus' : ''}`}
        onMouseEnter={toggleFocus(true)}
        onMouseLeave={toggleFocus(false)}
      >
        <span
          role="button"
          tabIndex={-1}
          className="c-mdPromo__close u-p-pointer u-t-nooutline"
          style={{ color: activeSlideTextColor }}
          onClick={handleClose}
        >
          <span
            className={`c-mdPromo__sym ic-bef ic-site-cross ${iconModifier}`}
            aria-hidden
          />
        </span>

        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider {...swiperSettings}>
          {/* https://github.com/akiran/react-slick/issues/1343 */}
          {isEmpty(displayList) ? (
            <Fragment key={0} />
          ) : (
            displayList
              .filter((m) => !isEmpty(m?.src_plain))
              .map((msg) => (
                <WrapTag
                  key={msg?.position}
                  message={msg}
                  bgColor={msg?.color_bg}
                  handleClick={handleClick}
                >
                  <figure className="c-mdPromo__fig">
                    <Imgix
                      className="c-mdPromo__img u-block"
                      sizes="100vw"
                      disableSrcSet
                      src={msg?.src_plain}
                      imgixParams={msg?.src_params}
                      htmlAttributes={{
                        alt: msg?.title,
                        draggable: false
                      }}
                    />
                  </figure>
                </WrapTag>
              ))
          )}
        </Slider>
      </div>
    </Beforeunload>
  );
}
