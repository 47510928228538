import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { noop } from 'lodash';

import airbrake from '~/libs/airbrake';
import { trackAuthResponse, trackAuthLead } from '~/utils/analytics/gtm';
import { FACEBOOK_LOGIN_API } from '~/containers/ModalsManager/constants';
import sharedAtoms from '~/containers/shared/states/atoms';
import atoms from '~/containers/ModalsManager/states/atoms';
import { submit } from '~/utils/requestAPI';

export default function useFacebookSignIn({
  authProvider,
  handleClose = noop
}) {
  const isClient = useRecoilValue(sharedAtoms.isClient);
  const setErrorMess = useSetRecoilState(atoms.authErrorMessage);
  const authConfig = useRecoilValue(atoms.authConfig);

  const facebookLogin = useCallback(
    async (response) => {
      try {
        const { accessToken, expires_in: expiresIn } = response;
        const body = {
          'facebook[token]': accessToken,
          'facebook[expires_in]': expiresIn,
          source_path: isClient ? window.location.pathname : ''
        };
        const { data } = await submit(FACEBOOK_LOGIN_API, body);
        if (data) {
          const { success, message } = data;
          if (success) {
            trackAuthLead({
              mode: authConfig?.authMode,
              content: authConfig?.authContent,
              authProvider
            });
            trackAuthResponse({
              authProvider,
              response: 'success'
            });
            handleClose();
            if (isClient) window.location.reload();
          } else {
            trackAuthResponse({
              authProvider,
              response: `invalid - ${message}`
            });
            setErrorMess(message);
          }
        }
      } catch (error) {
        trackAuthResponse({
          authProvider,
          response: 'failure'
        });
        airbrake.notify({ error });
      }
    },
    [
      authConfig?.authContent,
      authConfig?.authMode,
      authProvider,
      handleClose,
      isClient,
      setErrorMess
    ]
  );

  return facebookLogin;
}
