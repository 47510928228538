import { useCallback, useRef } from 'react';

import { sendDelete } from '~/utils/requestAPI';

export default function useLogout() {
  const isCancel = useRef(false);

  const logout = useCallback(async () => {
    if (isCancel.current) return;

    try {
      const res = await sendDelete('/logout');
      const { success } = res.data;

      if (success) {
        isCancel.current = true;
        window.location.reload();
      }
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  return logout;
}
