/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

export default function Popover({
  className,
  tooltipBlockName,
  tooltip,
  children,
  placement,
  trigger,
  visible,
  onVisibleChange,
  hideArrow,
  interactive,
  delayHide
}) {
  const {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
    getArrowProps,
    visible: visibleTooltip
  } = usePopperTooltip({
    placement,
    trigger,
    visible,
    onVisibleChange,
    interactive,
    delayHide
  });

  return (
    <>
      <span className={className} ref={setTriggerRef}>
        {children}
      </span>

      {visibleTooltip && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `${tooltipBlockName} is-pop-${placement}`
          })}
        >
          <div className={`${tooltipBlockName}__content`}>
            {!hideArrow && (
              <div
                {...getArrowProps({
                  className: `${tooltipBlockName}__arrow`
                })}
              />
            )}
            {tooltip}
          </div>
        </div>
      )}
    </>
  );
}
