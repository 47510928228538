import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { buildURL } from 'react-imgix';
import { isEmpty, noop } from 'lodash';

import { EXIT_INTENT_COUNT_KEY } from '~/containers/ModalsManager/constants';
import Popover from '~/components/shared/Popover';
import HvModal from '~/components/shared/HvModal';
import { useCalloutViewingEvents } from '~/hooks/shared/useCalloutsData';
import { commonParams } from '~/utils/imgix';
import { ga4Events } from '~/utils/analytics/gtm';
import { trackCalloutAction } from '~/containers/ModalsManager/analytics';
import './ModalExitIntent.scss';

const COPY_FEEDBACK_DELAY = 1000;
const BACKGROUND_IMAGE =
  'https://hiptruck-files.imgix.net/cms-files/19fec6ee-d4c7-4140-9c1d-15fc272749a6/bg.png';

export default function ModalExitIntent({
  data = {},
  visible = false,
  setVisible = noop,
  handleUnsub = noop
}) {
  const { viewCount, effect, code } = data;

  const [copyHover, setCopyHover] = useState(false);
  const [copied, setCopied] = useState(false);
  const [seen, setSeen] = useState(false);
  const [acted, setActed] = useState(false);

  const finalSrc = useMemo(
    () => buildURL(BACKGROUND_IMAGE, { ...commonParams, w: 600 }),
    []
  );

  const toggleTooltip = useCallback((bool) => () => setCopyHover(bool), []);

  const eventPayload = useMemo(
    () => ({
      image: BACKGROUND_IMAGE,
      message: effect,
      code,
      viewCount
    }),
    [code, effect, viewCount]
  );

  const handleOnCopy = useCallback(() => {
    setCopied(true);
    setActed(true);
    trackCalloutAction(ga4Events?.copy_callout_exit_intent, eventPayload);
  }, [eventPayload]);

  const { trackOnClose, trackBeforeunload } = useCalloutViewingEvents({
    impressionable: visible,
    intersecting: visible,
    countProp: EXIT_INTENT_COUNT_KEY,
    closeEvent: ga4Events?.close_callout_exit_intent,
    impressionEvent: ga4Events?.view_callout_exit_intent,
    payload: eventPayload,
    seen,
    setSeen
  });

  const handleClose = useCallback(() => {
    setVisible(false);
    handleUnsub();
    trackOnClose(acted);
  }, [acted, handleUnsub, setVisible, trackOnClose]);

  useEffect(() => {
    let delayReset;

    if (copied) {
      delayReset = setTimeout(() => setCopied(false), COPY_FEEDBACK_DELAY);
    }

    return () => {
      clearTimeout(delayReset);
    };
  }, [copied]);

  useEffect(() => {
    if (isEmpty(data)) setVisible(false);
  }, [data, setVisible]);

  return (
    <Beforeunload onBeforeunload={() => trackBeforeunload(acted)}>
      <HvModal
        onClose={handleClose}
        isOpen={visible}
        contentLabel="Exit Intent"
        blockName="c-mdExit"
        defaultHeader={false}
        customStyles={{ backgroundImage: `url(${finalSrc})` }}
      >
        <span
          role="button"
          tabIndex={-1}
          className="c-mdExit__close u-p-pointer u-t-nooutline u-t-body"
          onClick={handleClose}
        >
          <span
            className="c-mdExit__sym ic-bef ic-site-cross ic-xbld"
            aria-hidden
          />
        </span>
        <div className="c-mdExit__header">
          <p className="c-mdExit__pre">Don&apos;t leave just yet!</p>
          <p className="c-mdExit__effect u-t-bold">{effect}</p>
          <p className="c-mdExit__post">when you checkout with this code:</p>
        </div>
        <div className="c-mdExit__action">
          <Popover
            className="c-mdExit__hover"
            placement="top"
            trigger="hover"
            tooltipBlockName="c-mdExitTip"
            tooltip={copied ? 'Code copied!' : 'Copy this code'}
            visible={copied || copyHover}
          >
            <CopyToClipboard text={code} onCopy={handleOnCopy}>
              <div
                className="c-mdExit__code u-b-white u-p-pointer u-t-nooutline u-t-ellipsis u-animate-all"
                onMouseEnter={toggleTooltip(true)}
                onMouseLeave={toggleTooltip(false)}
              >
                {code}
              </div>
            </CopyToClipboard>
          </Popover>

          <CopyToClipboard text={code} onCopy={handleOnCopy}>
            <button
              type="button"
              className="c-mdExit__cta o-btn o-fd o-fd-primary"
              onMouseEnter={toggleTooltip(true)}
              onMouseLeave={toggleTooltip(false)}
            >
              <span>Copy Code</span>
            </button>
          </CopyToClipboard>
        </div>
      </HvModal>
    </Beforeunload>
  );
}
