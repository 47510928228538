import { useState, useEffect } from 'react';
import { useScrollData as rawScrollData } from 'scroll-data-hook';
import { isFinite } from 'lodash';

export default function useScrollData(ready) {
  const [scrLastDir, setScrLastDir] = useState('down');
  const [scrLastPos, setScrLastPos] = useState(0);
  const {
    scrolling,
    direction: { y: dirY = null },
    position: { y: posY = null }
  } = rawScrollData();

  useEffect(() => {
    if (ready && scrolling && !!dirY) {
      setScrLastDir(dirY);
    }
    if (ready && scrolling && isFinite(posY)) {
      setScrLastPos(posY);
    }
  }, [dirY, posY, ready, scrolling]);

  return { direction: scrLastDir, position: scrLastPos };
}
