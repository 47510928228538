import airbrake from '~/libs/airbrake';

const amplitudeTrack = (eventName, data) => {
  if (typeof window !== 'object') return;
  if (globalThis?.isMobileApp) return;

  try {
    window?.amplitude?.track(eventName, data);
  } catch (error) {
    airbrake.notify({ error });
  }
};

export { amplitudeTrack };
