import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';

import { AUTH_CONTENT } from '~/containers/ModalsManager/constants';

export const authAction = (isLogin) => (isLogin ? 'Log in' : 'Sign up');

export function Greeting({ blockName = '', title, authContent, children }) {
  if (isEmpty(title) && isEmpty(children)) return null;

  return (
    <div className={`${blockName}__greet`} data-content={authContent}>
      {!isEmpty(title) && (
        <div className={`${blockName}__title u-t-bold`}>{title}</div>
      )}
      {!isEmpty(children) && (
        <div className={`${blockName}__blurb`}>{children}</div>
      )}
    </div>
  );
}

export default function AuthGreetings({
  blockName = '',
  isLogin = true,
  authContent = AUTH_CONTENT?.GENERAL
}) {
  const { header, subheader } = useMemo(() => {
    const authText = authAction(isLogin);

    let head = 'Welcome.';
    let subhead = (
      <>
        {`${authText} to access your `}
        <span className="u-t-bold">order history, save your favourites</span>
        {' and your cart, '}
        <span className="u-t-bold">earn credits</span>{' '}
        <span className="u-t-nowrap">& more!</span>
      </>
    );

    switch (authContent) {
      case AUTH_CONTENT?.REVIEWS:
        head = 'Writing a review?';
        subhead = (
          <>
            {`${authText} to `}
            <span className="u-t-bold">write product reviews</span>
            {', save your '}
            <span className="u-t-bold">favourites</span>
            {', access your order history '}
            <span className="u-t-nowrap">& more!</span>
          </>
        );
        break;
      case AUTH_CONTENT?.REWARDS:
        head = (
          <>
            Get Your <span className="u-inline-block">Reward Points Now</span>
          </>
        );
        subhead = (
          <>
            {`${authText} for a HipVan user account to retrieve `}
            <span className="u-t-nowrap">your points.</span>
          </>
        );
        break;
      case AUTH_CONTENT?.REFERRAL:
        head = 'Refer your friends now!';
        subhead = (
          <>
            {'Earn '}
            <span className="u-t-bold">$30 credits</span>
            {' for every friend you refer!'}
          </>
        );
        break;
      case AUTH_CONTENT?.WISHLIST:
        head = 'Saving your wishlist?';
        subhead = (
          <>
            {`${authText} to `}
            <span className="u-t-bold">save your favourites</span>
            {' and your cart, '}
            <span className="u-t-bold">earn credits</span>
            {', access your order history '}
            <span className="u-t-nowrap">& more!</span>
          </>
        );
        break;
      case AUTH_CONTENT?.NOTIFY:
        // head = '';
        subhead = (
          <>
            {`${authText} and be notified when it's back `}
            <span className="u-t-nowrap">in stock!</span>
          </>
        );
        break;
      case AUTH_CONTENT?.LIKES:
        head = 'Loving this home?';
        subhead = (
          <>
            {`${authText} to continue supporting `}
            the community&apos;s countless inspiring posts!
          </>
        );
        break;
      case AUTH_CONTENT?.COMMENT_LIKES:
        head = 'Agree with this comment?';
        subhead = (
          <>
            {`${authText} to continue supporting what other community members `}
            <span className="u-t-nowrap">have to say!</span>
          </>
        );
        break;
      case AUTH_CONTENT?.COMMENTS:
        head = (
          <>
            {'Leave your '}
            <span className="u-t-nowrap">first comment!</span>
          </>
        );
        subhead = (
          <>
            {`${authText} to leave supportive messages and burning questions on `}
            <span className="u-t-nowrap">homeowners&apos; posts!</span>
          </>
        );
        break;
      case AUTH_CONTENT?.FOLLOWS:
        head = (
          <>
            {'Want to see more '}
            <span className="u-t-nowrap">of them?</span>
          </>
        );
        subhead = (
          <>
            {`${authText} to easily keep track `}
            of your favourite homeowners&apos; posts!
          </>
        );
        break;
      case AUTH_CONTENT?.POST_JOIN:
        head = "You're almost there!";
        subhead = (
          <>
            {`${authText} to showcase your home to `}
            &gt;200,000 members in the community!
          </>
        );
        break;
      case AUTH_CONTENT?.COMMUNITY:
        head = (
          <>
            {"Let's build your "}
            <span className="u-t-nowrap">dream home.</span>
          </>
        );
        subhead = (
          <>
            {`${authText} to interact and learn from other `}
            homeowners to better furnish your own home!
          </>
        );
        break;
      case AUTH_CONTENT?.SAVES:
        head = 'Love It? Save It!';
        subhead = (
          <>
            {`${authText} to curate your inspirational `}
            moodboard and refer to it easily anytime, anywhere.
          </>
        );
        break;
      default:
        break;
    }

    return { header: head, subheader: subhead };
  }, [authContent, isLogin]);

  return (
    <Greeting blockName={blockName} title={header} authContent={authContent}>
      {subheader}
    </Greeting>
  );
}
