import React from 'react';

import './ErrorPanel.scss';

export default function ErrorPanel({ modifier = '', children }) {
  return (
    <div className={`c-errorPanel is-${modifier}`}>
      <div className="c-errorPanel__img" />
      <div className="c-errorPanel__well">{children}</div>
    </div>
  );
}
