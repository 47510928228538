import React, { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useCookies } from 'react-cookie';

import atoms from '~/containers/shared/states/atoms';
import NavIcon from '~/components/Header/NavIcon';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import './CartIcon.scss';

const CART_ITEMS_KEY = 'cart_items';

export default function CartIcon() {
  const [cartCount, setCartCount] = useRecoilState(atoms.cartCount);
  const [cookies] = useCookies([CART_ITEMS_KEY]);

  const onClickCart = useCallback(() => {
    if (globalThis?.HV?.CartBox) {
      // TODO: migrating cart popup logic to React
      globalThis.HV.CartBox.userTriggeredState = true;
      globalThis.HV.CartBox.toggleShowingSideCartBox(true);
    }
  }, []);

  useEffect(() => {
    setCartCount(defaultForUndefinedOrNull(cookies?.[CART_ITEMS_KEY], 0));
  }, [cookies, setCartCount]);

  return (
    <div
      role="button"
      tabIndex={-1}
      className="c-nvCartBtn u-inline-block u-p-pointer"
      onClick={onClickCart}
    >
      <NavIcon iconClass="ic-site-cartnum" count={cartCount} />
    </div>
  );
}
