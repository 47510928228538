import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';
import parse from 'html-react-parser';

import atoms from '~/containers/shared/states/atoms';
import MainStack from '~/components/Header/NavDropdownMenu/MainStack';
import './RootDropdown.scss';

const MAX_COLUMNS = 5;

export default function RootDropdown({
  mainCats = [],
  cmsCols = 0,
  cmsContent = null,
  cmsOnly = false
}) {
  const isClient = useRecoilValue(atoms.isClient);

  const cmsColumnCount = useMemo(
    () => Math.min(cmsCols, MAX_COLUMNS),
    [cmsCols]
  );

  const stdColumnCount = useMemo(
    () =>
      isEmpty(cmsContent)
        ? MAX_COLUMNS
        : Math.min(MAX_COLUMNS - cmsCols, MAX_COLUMNS),

    [cmsCols, cmsContent]
  );

  const hasStdCols = useMemo(
    () => stdColumnCount > 0 && !isEmpty(mainCats),
    [mainCats, stdColumnCount]
  );

  const hasCmsCols = useMemo(
    () => cmsColumnCount > 0 && !isEmpty(cmsContent),
    [cmsColumnCount, cmsContent]
  );

  if (!cmsOnly && !hasStdCols && !hasCmsCols) return null;

  if (cmsOnly)
    return (
      <div className="c-nvRootDrop u-animate-all">
        <div className="c-nvRootDrop__cols is-cms is-5-col">
          {isClient && parse(cmsContent)}
        </div>
      </div>
    );

  return (
    <div className="c-nvRootDrop u-animate-all">
      {hasStdCols && (
        <ul
          className={`c-nvRootDrop__cols is-std u-minied-list is-${stdColumnCount}-col`}
        >
          {mainCats.map((node) => (
            <MainStack
              key={node?.id}
              wrapperClass={`c-nvRootDrop__stack ${
                node?.nav_block_fit ? 'is-fit' : ''
              }`}
              data={node}
            />
          ))}
        </ul>
      )}
      {hasCmsCols && (
        <div className={`c-nvRootDrop__cols is-cms is-${cmsColumnCount}-col`}>
          {isClient && parse(cmsContent)}
        </div>
      )}
    </div>
  );
}
