import React, { useMemo, useCallback } from 'react';

import { NAV_MODES, trackNavAction } from '~/containers/Header/analytics';
import { ga4Events } from '~/utils/analytics/gtm';
import './StackLink.scss';

export default function StackLink({ data = {}, hierarchy }) {
  const {
    taxon_id: taxonId = null,
    name = null,
    relative_url: relativeUrl = null
  } = data;

  const classnames = useMemo(
    () =>
      [
        'c-nvStackLink',
        'u-block',
        'u-t-white',
        'u-t-nolined',
        'u-t-nooutline',
        'u-t-ellipsis',
        'is-still',
        !!hierarchy && `is-${hierarchy}`
      ]
        .filter((n) => !!n)
        .join(' '),
    [hierarchy]
  );

  const handleClick = useCallback(
    () =>
      trackNavAction(ga4Events?.nav_click_link, {
        mode: NAV_MODES?.dropdown_link,
        path: relativeUrl,
        hierarchy
      }),
    [hierarchy, relativeUrl]
  );

  return (
    <a
      className={classnames}
      data-nvid={taxonId}
      href={relativeUrl}
      title={name}
      onClick={handleClick}
    >
      {name}
    </a>
  );
}
