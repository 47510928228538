import React from 'react';
import Modal from 'react-modal';
import { noop } from 'lodash';

import './HvModal.scss';

const STANDARD_MODIFIERS = {
  afterOpen: 'has-opened',
  beforeClose: 'is-closing'
};

export default function HvModal({
  blockName = 'c-HvModal',
  contentLabel = '',
  dialogBg = 'u-b-white',
  customStyles = {},
  isOpen = false,
  closeTimeoutMS = 300,
  defaultHeader = true,
  onClose = noop,
  otherConfig = {},
  children = null
}) {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={contentLabel}
      bodyOpenClassName="has-rcModal"
      portalClassName={`c-hvModal ${blockName}`}
      overlayClassName={{
        base: `c-hvModal__overlay ${blockName}__overlay`,
        ...STANDARD_MODIFIERS
      }}
      className={{
        base: `c-hvModal__dialog ${blockName}__dialog u-t-nooutline ${dialogBg}`,
        ...STANDARD_MODIFIERS
      }}
      onRequestClose={onClose}
      closeTimeoutMS={closeTimeoutMS}
      style={{
        content: customStyles
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherConfig}
    >
      {defaultHeader && (
        <div className={`c-hvModal__cap ${blockName}__cap`}>
          <span
            role="button"
            tabIndex={-1}
            className={`c-hvModal__cap-btn ${blockName}__cap-btn u-p-pointer u-t-nooutline ic-bef ic-site-cross ic-xbld`}
            aria-hidden
            onClick={onClose}
          />
        </div>
      )}
      <div className={`c-hvModal__body ${blockName}__body is-modal`}>
        {children}
      </div>
    </Modal>
  );
}
