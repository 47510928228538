import React from 'react';
import ErrorPanel from '~/components/shared/ErrorPanel';
import airbrake from '~/libs/airbrake';

export default class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.log('Error: ', error);
    console.log('Info: ', info);
    if (process.env.RAILS_ENV !== 'development') {
      airbrake.notify({ error, params: { info } });
    }
  }

  render() {
    const { hasError } = this.state;
    const { location = 'unknown_ErrorBoundary' } = this.props;

    if (hasError) {
      return (
        <ErrorPanel modifier={location}>
          <div className="c-errorPanel__loadError">Failed to load</div>
        </ErrorPanel>
      );
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}
