import { useMemo } from 'react';

import { Info, DateTime } from 'luxon';
import { defaultForUndefinedOrNull } from '~/utils/helper';

const MAX_DAYS = 31;

export default function useYearMonthDay({
  yearsBack = 72,
  monthNow = null,
  yearNow = null
}) {
  const yearOptions = useMemo(() => {
    const list = [];
    const max = new Date().getUTCFullYear();
    const min = max - yearsBack;
    let counter = max;

    while (counter >= min) {
      list.push({ index: counter, value: counter, title: counter });
      // eslint-disable-next-line no-plusplus
      counter--;
    }
    return list;
  }, [yearsBack]);

  const monthOptions = useMemo(() => {
    const raw = defaultForUndefinedOrNull(Info?.months(), []);
    return raw.map((mo, idx) => ({ index: idx, value: idx + 1, title: mo }));
  }, []);

  const dayOptions = useMemo(() => {
    const isSafeMonth = monthOptions.map((mo) => mo.value).includes(monthNow);
    const isSafeYear = yearOptions.map((year) => year.value).includes(yearNow);
    const daysCount =
      isSafeMonth && isSafeYear
        ? defaultForUndefinedOrNull(
            DateTime?.local(yearNow, monthNow)?.daysInMonth,
            MAX_DAYS
          )
        : MAX_DAYS;
    return Array.from({ length: daysCount }, (_, k) => k + 1).map((day) => ({
      index: day,
      value: day,
      title: day
    }));
  }, [monthNow, monthOptions, yearNow, yearOptions]);

  return {
    yearOptions,
    monthOptions,
    dayOptions
  };
}
