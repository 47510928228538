import axios from 'axios';
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer
} from 'axios-extensions';

import RailsVars from '~/apps/railsVariables.js.erb';

const DEFAULT_VERSION = 'v1';

export default function createInstance(version = DEFAULT_VERSION) {
  return axios.create({
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Session-Id': globalThis.CUSTOMER_SESSION_ID
    },
    baseURL: `${RailsVars.BASE_API_URL}/api/${version}`,
    adapter: throttleAdapterEnhancer(
      cacheAdapterEnhancer(axios.defaults.adapter)
    )
  });
}
