import { useRef, useMemo, useEffect } from 'react';
import { noop, isFunction, debounce } from 'lodash';

export default function useDebounce(cb = noop, delay = 1000) {
  const ref = useRef();

  const debouncedCb = useMemo(
    () =>
      debounce(
        () => (isFunction(ref?.current) ? ref.current?.() : noop()),
        delay
      ),
    [delay]
  );

  useEffect(() => {
    ref.current = cb;
  }, [cb]);

  return debouncedCb;
}
