import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';

import NavIcon from '~/components/Header/NavIcon';
import { userDataDisplayPic } from '~/utils/helper';

export default function Avatar({ user = {} }) {
  const profileImage = useMemo(() => userDataDisplayPic(user), [user]);

  return (
    <NavIcon
      iconClass="ic-site-user"
      image={profileImage}
      isPadded={!isEmpty(profileImage)}
    />
  );
}
