import axios from 'axios';
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer
} from 'axios-extensions';
import queryString from 'query-string-for-all';

import RailsVars from '~/apps/railsVariables.js.erb';

const instance = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-Api-Key': RailsVars.CDN_X_API_KEY,
    'X-Session-Id': globalThis.CUSTOMER_SESSION_ID,
    Authorization: `Bearer ${globalThis.CURRENT_USER_DATA?.auth_data?.access_token}`
  },
  baseURL: `${RailsVars.CDN_BASE_API_URL}/api/v1`,
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter))
});

export const errorHandler = (error) => {
  if (!axios.isCancel(error)) {
    let res;
    const buildKey = (name) => `REQUEST_AXIOS_ERROR_${name}`;
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errObj = { error: error.response };
      const { status } = error.response;
      res = {
        key: buildKey(status),
        status,
        ...errObj
      };
      window[res] = errObj;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      const errObj = { error: error.request };
      const { status } = error.request;
      res = {
        key: buildKey(status),
        status,
        ...errObj
      };
      window[res] = errObj;
    } else {
      // Something happened in setting up the request that triggered an Error
      res = {
        key: `REQUEST_AXIOS_ERROR: ${error.message}`,
        status: -1,
        ...error
      };
      window.REQUEST_AXIOS_ERROR = { error };
    }
    return res;
  }
  return {
    message: 'Opps! Something went wrong while setting up request',
    status: -1
  };
};

export const cancelSource = axios.CancelToken.source();

export const SUCCESS_CODE = 200;

export const sendGet = async (path, queries = {}, options = {}) => {
  try {
    const urlWithQueries = queryString.stringifyUrl({
      url: path,
      query: queries
    });
    return await instance.get(urlWithQueries, options);
  } catch (error) {
    return errorHandler(error);
  }
};

export const sendPost = async (path, body, options = {}) => {
  try {
    return await instance.post(path, { ...body }, options);
  } catch (error) {
    return errorHandler(error);
  }
};

export const sendPatch = async (path, body, options = {}) => {
  try {
    return await instance.put(path, { ...body }, options);
  } catch (error) {
    return errorHandler(error);
  }
};

export const sendDelete = async (path, option = {}) => {
  try {
    return await instance.delete(path, option);
  } catch (error) {
    return errorHandler(error);
  }
};
