import { capitalize, isEmpty } from 'lodash';
import i18n from '~/i18n';

import { allowZdWidget, toggleZdWidget } from '~/utils/zendeskControls';

import {
  CONTEST_STATUS,
  ARTICLE_LIST_GRID,
  POSTS_LIST_GRID,
  BLOG_INDEX_PATH,
  HOUSEWARM_INDEX_PATH,
  HOUSEWARM_NEW_PATH,
  PICTURES_INDEX_PATH,
  PICTURES_NEW_PATH,
  CONTESTS_INDEX_ITEM,
  CONTESTS_INDEX_PATH,
  CONTENT_TYPE
} from '~/containers/Blog/constants';
import {
  POST_PUBLIC,
  ARTICLE_PUBLIC
} from '~/components/Blog/ContentGrid/Config';
import { defaultForUndefinedOrNull, profileDisplayName } from '~/utils/helper';

const safePostTitle = ({
  postTitle = null,
  postAuthorData = {},
  contentType = CONTENT_TYPE?.POST
}) => {
  return defaultForUndefinedOrNull(
    postTitle,
    `${profileDisplayName(postAuthorData)}'s ${capitalize(
      i18n.t(`content:${contentType}.unit`)
    )}`
  );
};

const contestUiConfig = (primaryContentType) => {
  let data = {
    gridConfig: POSTS_LIST_GRID,
    cardType: POST_PUBLIC,
    contentType: 'anysubmit'
  };
  switch (primaryContentType) {
    case CONTENT_TYPE?.EDITORIAL:
      data = {
        gridConfig: ARTICLE_LIST_GRID,
        cardType: ARTICLE_PUBLIC,
        contenType: primaryContentType
      };
      break;
    case CONTENT_TYPE?.HOUSEWARM:
      data = {
        gridConfig: ARTICLE_LIST_GRID,
        cardType: ARTICLE_PUBLIC,
        contentType: primaryContentType
      };
      break;
    case CONTENT_TYPE?.POST:
    case CONTENT_TYPE?.PICTURE:
      data.contentType = primaryContentType;
      break;
    default:
      break;
  }
  return data;
};

const contestRoutesConfig = (contest) => {
  let voteAll;
  let entryType;
  let entryForm;
  switch (contest?.primary_content_type) {
    case CONTENT_TYPE?.HOUSEWARM:
      voteAll = HOUSEWARM_INDEX_PATH;
      entryType = contest?.primary_content_type;
      entryForm = HOUSEWARM_NEW_PATH;
      break;
    case CONTENT_TYPE?.POST:
      voteAll = PICTURES_INDEX_PATH;
      entryType = contest?.primary_content_type;
      entryForm = PICTURES_NEW_PATH;
      break;
    default:
      voteAll = CONTESTS_INDEX_ITEM;
      entryType = 'anysubmit';
      entryForm = null;
  }

  if (isEmpty(contest) || !entryForm)
    return {
      type: entryType,
      join: null,
      vote: null,
      live: false
    };

  if (isEmpty(contest?.handle))
    return {
      type: entryType,
      join: entryForm,
      vote: voteAll,
      live: false
    };

  return {
    type: entryType,
    join: `${entryForm}?contest=${contest?.handle}`,
    vote: `${CONTESTS_INDEX_PATH}/${contest?.handle}`,
    live: contest?.run_status === CONTEST_STATUS.ACTIVE
  };
};

const fieldUi = (item) => {
  const displayValue = [item?.prefix, item?.value, item?.suffix]
    .filter((v) => !!v)
    .join(' ');

  return {
    field_value: displayValue,
    tooltip: [item?.display_label, displayValue].filter((n) => !!n).join(': ')
  };
};

const showComment = (comment) =>
  !defaultForUndefinedOrNull(comment?.archived, false) &&
  defaultForUndefinedOrNull(comment?.is_visible, true);

const blockZendeskPath = () =>
  typeof window === 'object'
    ? globalThis.location.pathname.indexOf(BLOG_INDEX_PATH) >= 0
    : true;

const blockZendeskWidget = () => {
  allowZdWidget(false);
  toggleZdWidget(false, true);
};

export {
  contestUiConfig,
  contestRoutesConfig,
  fieldUi,
  showComment,
  safePostTitle,
  blockZendeskPath,
  blockZendeskWidget
};
