import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import Truncate from 'react-truncate';

import atoms from '~/containers/Header/states/atoms';
import HomePro from '~/components/shared/PrefixIcon/HomePro';
import useWindowWidth from '~/hooks/shared/useWindowWidth';
import { profileDisplayName } from '~/utils/helper';

export default function DecoratedName({
  user = {},
  flagClass = '',
  offsetSpace = 5,
  decorate = false,
  recalcOnFontsLoad = false
}) {
  const viewportWidth = useWindowWidth();
  const setRecalcOnFontsLoad = useSetRecoilState(atoms.recalcOnFontsLoad);
  const [truncated, setTruncated] = useState(true);

  const displayName = useMemo(() => profileDisplayName(user), [user]);

  const isPro = useMemo(
    () => decorate && user?.professional?.valid === true,
    [decorate, user?.professional?.valid]
  );

  const offsetName = useMemo(
    () =>
      isPro
        ? `${Array(offsetSpace).fill('\u00A0').join('')}${displayName}`
        : displayName,
    [displayName, isPro, offsetSpace]
  );

  const handleTruncate = useCallback(
    (trunc) => {
      if (truncated !== trunc) setTruncated(trunc);
    },
    [setTruncated, truncated]
  );

  useEffect(() => {
    if (recalcOnFontsLoad) setRecalcOnFontsLoad(true);
  }, [recalcOnFontsLoad, setRecalcOnFontsLoad, viewportWidth]);

  return (
    <>
      {isPro && <HomePro wrapperClass={flagClass} />}
      <Truncate trimWhitespace lines={1} onTruncate={handleTruncate}>
        {offsetName}
      </Truncate>
    </>
  );
}
