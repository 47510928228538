/* eslint-disable react/jsx-props-no-spreading,jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect } from 'react';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import airbrake from '~/libs/airbrake';
import {
  CUSTOMER_INFO_FIELD_KEYS,
  UPDATE_PROFILE_API
} from '~/containers/Account/constants';
import sharedAtoms from '~/containers/shared/states/atoms';
import atoms from '~/containers/ModalsManager/states/atoms';
import HvModal from '~/components/shared/HvModal';
import AvatarField from '~/components/Account/ProfileUpdate/AvatarField';
import useNicknameValidator from '~/hooks/Account/useNicknameValidator';
import { sendPost } from '~/utils/requestAPI';
import { ga4Events } from '~/utils/analytics/gtm';
import {
  MODAL_PROFILE_INFO,
  trackOnboardModalAction
} from '~/containers/ModalsManager/analytics';

export default function ProfileInfoModal({ pathname = 'unknown' }) {
  const [showModal, setShowModal] = useRecoilState(atoms.showProfileInfoModal);
  const setReadyForCallouts = useSetRecoilState(atoms.readyForCallouts);
  const [loggedCustomer, setLoggedCustomer] = useRecoilState(
    sharedAtoms.currentCustomer
  );

  const { nickname, image_url: profilePic = '', slug } = loggedCustomer;

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { validateNickname, errorMsg } = useNicknameValidator();

  const onClose = useCallback(() => {
    setShowModal(false);
    setReadyForCallouts(true);
  }, [setReadyForCallouts, setShowModal]);

  const updateInfo = useCallback(
    async (form) => {
      try {
        const res = await sendPost(UPDATE_PROFILE_API, {
          customer: {
            ...form,
            nickname: isEmpty(form?.nickname) ? slug : form?.nickname
          }
        });
        const { success, message = '' } = res.data;
        if (success) {
          setLoggedCustomer(res?.data?.data?.customer);
          onClose();
        } else {
          airbrake.notify({ error: message });
        }
      } catch (error) {
        airbrake.notify({ error });
      }
    },
    [onClose, setLoggedCustomer, slug]
  );

  const onSubmit = useCallback(
    (formData) => {
      trackOnboardModalAction(
        ga4Events?.click_info_request_modal_success,
        MODAL_PROFILE_INFO,
        pathname
      );
      updateInfo({ ...formData, image_url: profilePic });
    },
    [pathname, profilePic, updateInfo]
  );

  useEffect(() => {
    if (showModal)
      trackOnboardModalAction(
        ga4Events?.view_info_request_modal,
        MODAL_PROFILE_INFO,
        pathname
      );
  }, [pathname, showModal]);

  return (
    <HvModal
      onClose={onClose}
      isOpen={showModal}
      blockName="c-infoReq"
      contentLabel="Request Update profile"
      otherConfig={{
        shouldCloseOnOverlayClick: false,
        shouldCloseOnEsc: false
      }}
      defaultHeader={false}
    >
      <div className="c-infoReq__section is-form">
        <div className="c-infoReq__cap">
          <h3 className="c-infoReq__cap-h u-t-bold">
            {'Your community profile will '}
            <span className="u-t-nowrap">look like this:</span>
          </h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="c-infoReq__field">
            <div className="c-infoReq__img">
              <AvatarField
                initialFiles={[profilePic]}
                customPrompt="Add Profile Picture"
              />
            </div>
          </div>
          <div className="c-infoReq__field">
            <label
              htmlFor={CUSTOMER_INFO_FIELD_KEYS.NICKNAME.id}
              className="c-infoReq__label u-block"
            >
              Nickname
            </label>
            <input
              type="text"
              className="c-infoReq__input o-input o-fd o-fd-greymid u-block"
              id={CUSTOMER_INFO_FIELD_KEYS.NICKNAME.id}
              defaultValue={nickname}
              placeholder="Your nickname"
              {...register(CUSTOMER_INFO_FIELD_KEYS.NICKNAME.fieldName, {
                validate: async (v) => validateNickname(v)
              })}
            />
            {errors[CUSTOMER_INFO_FIELD_KEYS.NICKNAME.fieldName] && (
              <label
                htmlFor={CUSTOMER_INFO_FIELD_KEYS.NICKNAME.id}
                className="error"
              >
                {errorMsg}
              </label>
            )}
          </div>
          <div className="c-infoReq__action">
            <button
              type="submit"
              className="c-infoReq__button o-btn o-fd o-fd-primary o-fd--block"
            >
              Get Started!
            </button>
          </div>
        </form>
      </div>
    </HvModal>
  );
}
