import ModularScale from 'modular-scale';

const GLOBAL_BASE_PX = 14;

const headingScale = ModularScale({
  ratio: ModularScale.ratios.majorSecond,
  base: `${GLOBAL_BASE_PX}px`
});

const paragraphScale = ModularScale({
  ratio: ModularScale.ratios.minorSecond,
  base: `${GLOBAL_BASE_PX}px`
});

export { GLOBAL_BASE_PX, headingScale, paragraphScale };
