import queryString from 'query-string-for-all';

import {
  RESULT_PAGE_URL,
  SUGGEST_SEARCH_TYPES
} from '~/containers/SearchResult/constants';

const searchHrefFor = ({
  search_type: searchType = '',
  name = '',
  url = ''
}) => {
  switch (searchType) {
    case SUGGEST_SEARCH_TYPES.hv_term: {
      return queryString.stringifyUrl({
        url: RESULT_PAGE_URL,
        query: { q: name }
      });
    }
    default: {
      return queryString.stringifyUrl({
        url,
        query: { opt: searchType }
      });
    }
  }
};

export default searchHrefFor;
