import { selector, selectorFamily } from 'recoil';
import { forIn } from 'lodash';

import { selectorKeys } from '~/containers/Header/constants';
import atoms from '~/containers/Header/states/atoms';
import { defaultForUndefinedOrNull } from '~/utils/helper';

const totalItemsInCart = selector({
  key: selectorKeys.TOTAL_ITEMS_IN_CART,
  get: ({ get }) => {
    let sum = 0;
    forIn(
      defaultForUndefinedOrNull(get(atoms.cartSessions)?.cart?.items, {}),
      function iteratee(v) {
        sum += Number(defaultForUndefinedOrNull(v?.quantity, 0));
      }
    );
    return sum;
  }
});

const searchFocusDropDownOpen = selectorFamily({
  key: selectorKeys.SEARCH_FOCUS_DROPDOWN_OPEN,
  get: ({ get }) => get(atoms.searchFocus),
  set:
    (isSync) =>
    ({ set }, toggle) => {
      set(atoms.searchFocus, toggle);
      if (isSync) set(atoms.dropdownOpen, toggle);
    }
});

const selectors = {
  totalItemsInCart,
  searchFocusDropDownOpen
};

export default selectors;
