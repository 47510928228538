import { selector } from 'recoil';
import { isEmpty, isFinite, merge } from 'lodash';

import RailsVars from '~/apps/railsVariables.js.erb';
import { selectorKeys } from '~/containers/shared/constants';
import atoms from '~/containers/shared/states/atoms';

const updateCurrentCustomer = selector({
  key: selectorKeys.UPDATE_CURRENT_CUSTOMER,
  get: ({ get }) => get(atoms.currentCustomer),
  set: ({ set }, data) => {
    if (!isEmpty(data))
      set(atoms.currentCustomer, (cur) => ({ ...cur, ...data }));
  }
});

const updatePrivateProfile = selector({
  key: selectorKeys.UPDATE_PRIVATE_PROFILE,
  get: ({ get }) => get(atoms.currentCustomer),
  set: ({ set }, data) => {
    if (!isEmpty(data))
      set(atoms.currentCustomer, (cur) => ({ ...cur, ...data }));
  }
});

const updatePublicProfile = selector({
  key: selectorKeys.UPDATE_PUBLIC_PROFILE,
  get: ({ get }) => get(atoms.publicProfile),
  set: ({ set }, data) => {
    if (!isEmpty(data))
      set(atoms.publicProfile, (cur) => ({ ...cur, ...data }));
  }
});

const updateReferralConfig = selector({
  key: selectorKeys.UPDATE_REFERRAL_CONFIG,
  get: ({ get }) => get(atoms.shoppingSummary?.referral_config?.valid),
  set: ({ set }, data) => {
    if (!isEmpty(data))
      set(atoms.shoppingSummary, (cur) =>
        merge({}, cur, {
          referral_config: {
            valid:
              !!RailsVars?.REFERRAL_MODULE_ACTIVATE &&
              [
                cur?.referral_config?.add_credits_to_referrer,
                cur?.referral_config?.discount_for_customer_referral
              ].every((value) => isFinite(value) && value > 0)
          }
        })
      );
  }
});

const sharedSelectors = {
  updateCurrentCustomer,
  updatePrivateProfile,
  updatePublicProfile,
  updateReferralConfig
};

export default sharedSelectors;
