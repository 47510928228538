import { defaultTo, fromPairs, isEmpty } from 'lodash';

const placeholdSrc =
  'https://hiptruck-files.imgix.net/cms-files/07ca76b2-b80c-4e60-af66-79f3e738890a/placeholder.gif';

const notFoundSrc =
  'https://hiptruck-files.imgix.net/cms-files/9f9b9555-6c91-4ef6-8c76-10b1d01d37d0/404.png';

const squareAr = '1:1';
const productAR = '100:74';

const commonParams = {
  fm: 'jpg',
  auto: 'format,compress',
  cs: 'srgb'
};

const hoverSwapParams = {
  ...commonParams,
  ar: squareAr,
  fit: 'crop',
  w: 400,
  bg: 'fff'
};

const pdtSwatchesParams = {
  ...commonParams,
  ar: productAR,
  fit: 'crop',
  w: 420
};

const colorVariantThumbParams = {
  ...commonParams,
  ar: squareAr,
  fit: 'crop',
  w: 60
};

const openGraphParams = {
  ...commonParams,
  ar: '1.91:1',
  fit: 'crop',
  w: 900
};

const fadeParams = {
  sat: -100,
  'blend-mode': 'normal',
  blend: '80ffffff'
};

const lazyAttribs = {
  src: 'data-src',
  srcSet: 'data-srcset',
  sizes: 'data-sizes'
};

const imgixDomain = 'imgix.net';

const decodeSafe = (uri) => {
  try {
    return decodeURIComponent(uri);
  } catch (e) {
    return uri;
  }
};

class UrlHelper {
  constructor(url = '') {
    this.cache = defaultTo(url, '');
    this.result = defaultTo(url, '');
    this.params = {};
  }

  // eslint-disable-next-line class-methods-use-this
  get cleanRegex() {
    return /\?.*/g;
  }

  // eslint-disable-next-line class-methods-use-this
  get domainRegex() {
    return /s3.*amazonaws\.com/g;
  }

  clean() {
    if (this.cleanRegex.exec(this.cache)) {
      this.paramObj();
    }
    this.result = decodeSafe(this.result.replace(this.cleanRegex, ''));
    return this;
  }

  paramObj() {
    const readyString = this.cleanRegex
      .exec(this.cache)[0]
      .replace('?', '')
      .split('&')
      .map((prop) => prop.split('='));
    this.params = fromPairs(readyString);
    return true;
  }

  toImgix() {
    this.result = decodeSafe(
      this.result.replace(this.domainRegex, imgixDomain)
    );
    return this;
  }

  validate(fallback = notFoundSrc) {
    if (isEmpty(this.result)) {
      this.result = fallback;
    }
    return this;
  }
}

const cleanUrl = (src, fallback = notFoundSrc) => {
  const h = new UrlHelper(src);
  return h.clean().validate(fallback).result;
};

const cleanUrlToImgix = (src, fallback = notFoundSrc) => {
  const h = new UrlHelper(src);
  return h.clean().toImgix().validate(fallback).result;
};

export {
  imgixDomain,
  placeholdSrc,
  notFoundSrc,
  squareAr,
  productAR,
  commonParams,
  hoverSwapParams,
  pdtSwatchesParams,
  colorVariantThumbParams,
  openGraphParams,
  fadeParams,
  lazyAttribs,
  UrlHelper,
  cleanUrl,
  cleanUrlToImgix
};
