import queryString from 'query-string-for-all';

import RailsVars from '~/apps/railsVariables.js.erb';
import {
  CONTENT_TYPE_LINK_DATA,
  CONTENT_TYPE
} from '~/containers/Blog/constants';
import { urlMergeParams, defaultForUndefinedOrNull } from '~/utils/helper';

const urlHandleFor = (url) => {
  let params = '';
  try {
    const urlParams = new URL(url);
    params = urlParams.pathname;
  } catch (error) {
    throw new Error(error);
  }
  return params;
};

const urlFromLocation = (data) => {
  const init = [data?.pathname, data?.search].filter((n) => !!n).join('?');
  return [init, data?.hash].filter((n) => !!n).join('#');
};

const buildFullUrl = (urlPath, glue = '') =>
  [RailsVars.BASE_LAC_LONG_QUAN_URL, urlPath].join(glue);

const buildFacebookShareLink = (shareUrl) =>
  queryString.stringifyUrl({
    url: 'https://www.facebook.com/sharer/sharer.php',
    query: {
      app_id: RailsVars.FACEBOOK_APP_ID,
      sdk: 'joey',
      u: shareUrl,
      display: 'popup',
      ref: 'plugin',
      src: 'share_button'
    }
  });

const contentViewingPath = ({
  item = {},
  linkContentTypeProp = 'parent_content_type',
  anchor = null
} = {}) => {
  const linkConfig =
    CONTENT_TYPE_LINK_DATA?.[
      defaultForUndefinedOrNull(
        item?.[linkContentTypeProp],
        CONTENT_TYPE?.UNKNOWN_TYPE
      )
    ];

  const prefix = linkConfig?.pathPrefix;
  const handle = defaultForUndefinedOrNull(item?.handle, null);

  if (!handle) return null;

  return urlMergeParams({
    rawUrl: [prefix, handle].filter((s) => !!s).join('/'),
    overrideHash: anchor
  });
};

const buildTwitterShareLink = (shareUrl) =>
  queryString.stringifyUrl({
    url: 'https://twitter.com/intent/tweet',
    query: {
      text: shareUrl
    }
  });

const buildWhatsappShareLink = (shareUrl) =>
  queryString.stringifyUrl({
    url: 'https://wa.me/',
    query: {
      text: shareUrl
    }
  });

const buildHipvanMailLink = (shareUrl) =>
  queryString.stringifyUrl({
    url: 'mailto:',
    query: {
      subject: '',
      body: shareUrl
    }
  });

const buildPinterestShareLink = (shareUrl) =>
  queryString.stringifyUrl({
    url: 'https://www.pinterest.com/pin/create/button',
    query: {
      guid: '3VakeW8HeVHn-2',
      url: shareUrl
    }
  });

export {
  urlHandleFor,
  urlFromLocation,
  buildFullUrl,
  contentViewingPath,
  buildFacebookShareLink,
  buildTwitterShareLink,
  buildWhatsappShareLink,
  buildHipvanMailLink,
  buildPinterestShareLink
};
