import React, { useState, useEffect } from 'react';
import Popover from '~/components/shared/Popover';

import '~/hooks/shared/useHotlineHours/HotlineHoursTip.scss';

const CUSTOM_EVENT_KEY = 'hours-loaded';

export function Tooltip({ days = null, dura = null, mailto, children = null }) {
  if (!children || (!mailto && !days && !dura)) return null;

  return (
    <Popover
      className="c-nvMenuDesk__hover u-inline-block"
      placement="bottom"
      trigger="hover"
      tooltipBlockName="c-hotlineTip"
      interactive
      tooltip={
        <div className="c-hotlineTip__inner">
          {!!mailto && (
            <div className="c-hotlineTip__row">
              <span className="u-block u-t-bold">Email us:</span>
              <a
                className="u-t-nolined u-t-white is-still"
                href={`mailto:${mailto}`}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {mailto}
              </a>
            </div>
          )}
          {(days || dura) && (
            <div className="c-hotlineTip__row">
              <span className="u-block u-t-bold">Live chat:</span>
              {days}
              {days && dura && <br />}
              {dura}
            </div>
          )}
        </div>
      }
    >
      {children}
    </Popover>
  );
}

export default function useHotlineHours() {
  const [hotlineHours, setHotlineHours] = useState({});

  useEffect(() => {
    $('body').on(CUSTOM_EVENT_KEY, () => {
      setHotlineHours(window.BUSINESS_HOURS);
    });

    return () => {
      $('body').off(CUSTOM_EVENT_KEY);
    };
  }, []);

  return hotlineHours;
}
