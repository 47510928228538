import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';

import atoms from '~/containers/Header/states/atoms';
import NavIcon from '~/components/Header/NavIcon';
import NavBurgerMenu from '~/components/Header/NavBurgerMenu';
import { toggleZdWidget } from '~/utils/zendeskControls';
import { trackNavBurgerOpen } from '~/containers/Header/analytics';

export default function BurgerIcon() {
  const [burgerOpen, setBurgerOpen] = useRecoilState(atoms.burgerOpen);

  const handleOpen = useCallback(() => {
    setBurgerOpen(true);
    trackNavBurgerOpen();
  }, [setBurgerOpen]);

  const handleClose = useCallback(() => {
    setBurgerOpen(false);
  }, [setBurgerOpen]);

  const onChange = useCallback((open) => toggleZdWidget(!open), []);

  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        className="u-inline-block u-p-pointer u-t-nooutline"
        onClick={handleOpen}
      >
        <NavIcon iconClass="ic-site-categories" />
      </div>
      <Drawer
        wrapperClassName="s-burgerDrawer"
        open={burgerOpen}
        onClose={handleClose}
        afterVisibleChange={onChange}
        handler={false}
        level={null}
      >
        <NavBurgerMenu />
      </Drawer>
    </>
  );
}
