/* eslint-disable react/no-array-index-key */

import React from 'react';
import queryString from 'query-string-for-all';

import { SHOWROOM_NAME } from '~/containers/shared/constants';
import { BLOG_INDEX_NAME, BLOG_INDEX_PATH } from '~/containers/Blog/constants';
import {
  ABOUT_US_PATH,
  FAQ_PATH,
  FAQS_TOP_HASH,
  FAQS_RETURNS_HASH,
  CONTACT_US_PATH,
  TERMS_CONDITIONS_PATH,
  SHOWROOM_CMS_PAGE_PATH,
  BUSINESS_PATH,
  REVIEWS_PATH,
  JOBS_PATH
} from '~/utils/pagePaths';
import './BurgerLinks.scss';

const QUICK_LINKS = [
  [
    {
      label: 'About',
      path: ABOUT_US_PATH
    },
    {
      label: 'Returns',
      path: queryString.stringifyUrl({
        url: FAQ_PATH,
        fragmentIdentifier: FAQS_RETURNS_HASH
      })
    },
    {
      label: 'Shipping',
      path: queryString.stringifyUrl({
        url: FAQ_PATH,
        fragmentIdentifier: FAQS_TOP_HASH
      })
    },
    {
      label: 'FAQ',
      path: FAQ_PATH
    },
    {
      label: 'Contact',
      path: CONTACT_US_PATH
    },
    {
      label: 'Terms',
      path: TERMS_CONDITIONS_PATH
    }
  ],
  [
    {
      label: BLOG_INDEX_NAME,
      path: BLOG_INDEX_PATH
    },
    {
      label: SHOWROOM_NAME,
      path: SHOWROOM_CMS_PAGE_PATH
    },
    {
      label: 'HipVan for Business',
      path: BUSINESS_PATH
    },
    {
      label: 'Reviews',
      path: REVIEWS_PATH
    },
    {
      label: 'Jobs',
      path: JOBS_PATH
    }
  ]
];

export default function BurgerLinks() {
  return (
    <section className="c-nvBlinks">
      {QUICK_LINKS.map((side, listId) => (
        <ul key={listId} className="c-nvBlinks__list u-minied-list">
          {side.map(({ path, label }, linkId) => (
            <li key={linkId} className="c-nvBlinks__item">
              <a
                className="c-nvBlinks__link u-block u-t-white u-t-nolined u-t-nooutline is-still"
                href={path}
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
      ))}
    </section>
  );
}
