import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { useCookies } from 'react-cookie';
import Imgix from 'react-imgix';
import { DateTime } from 'luxon';
import { isEmpty, noop } from 'lodash';

import {
  LEADSUB_MAIL_LIST_ID,
  LEADSUB_COUNT_KEY,
  LEADSUB_PAUSE_KEY,
  LEADSUB_SUCCESS_KEY
} from '~/containers/ModalsManager/constants';
import HvModal from '~/components/shared/HvModal';
import SubscribeForm, {
  DEFAULT_SUCCESS_FLAG
} from '~/components/shared/SubscribeForm';

import { useCalloutViewingEvents } from '~/hooks/shared/useCalloutsData';
import { localStorageHelper } from '~/utils/storageHelper';
import { commonParams } from '~/utils/imgix';
import { ga4Events } from '~/utils/analytics/gtm';
import { trackCalloutAction } from '~/containers/ModalsManager/analytics';
import './ModalLeadSub.scss';

const DEFAULT_BACKGROUND =
  'https://hiptruck-files.imgix.net/cms-files/769ea221-f29a-4128-8e76-7b2a8deea23c/Default-Rect-Lead-Gen.png';

export default function ModalLeadSub({
  data = {},
  visible = false,
  setVisible = noop
}) {
  const {
    viewCount,
    tagline,
    title,
    header,
    image = DEFAULT_BACKGROUND,
    retry
  } = data;

  const [, setCookie] = useCookies([LEADSUB_PAUSE_KEY]);
  const [success, setSuccess] = useState(false);
  const [seen, setSeen] = useState(false);
  const [acted, setActed] = useState(false);

  const eventPayload = useMemo(
    () => ({
      image,
      message: [tagline, title, header].filter((t) => !!t).join(' | '),
      listId: LEADSUB_MAIL_LIST_ID,
      viewCount
    }),
    [header, image, tagline, title, viewCount]
  );

  const handleUnsub = useCallback(
    () =>
      setCookie(LEADSUB_PAUSE_KEY, 1, {
        expires: DateTime.now().plus(retry).toJSDate(),
        path: '/',
        sameSite: 'Strict'
      }),
    [retry, setCookie]
  );

  const beforeunloadCb = useCallback(() => {
    if (success) localStorageHelper.setItem(LEADSUB_SUCCESS_KEY, 1);
  }, [success]);

  const handleOnResponse = useCallback(
    (result) => {
      setActed(true);
      trackCalloutAction(
        ga4Events?.submit_callout_lead_sub,
        { ...eventPayload, result },
        () => {
          if (result === DEFAULT_SUCCESS_FLAG) setSuccess(true);
        }
      );
    },
    [eventPayload]
  );

  const { trackOnClose, trackBeforeunload } = useCalloutViewingEvents({
    impressionable: visible && !success,
    intersecting: visible,
    countProp: LEADSUB_COUNT_KEY,
    closeEvent: ga4Events?.close_callout_lead_sub,
    impressionEvent: ga4Events?.view_callout_lead_sub,
    payload: {
      ...eventPayload,
      result: success ? DEFAULT_SUCCESS_FLAG : undefined
    },
    seen,
    setSeen,
    beforeunloadCb
  });

  const handleClose = useCallback(() => {
    setVisible(false);
    handleUnsub();
    trackOnClose(acted);
  }, [acted, handleUnsub, setVisible, trackOnClose]);

  useEffect(() => {
    setVisible(!isEmpty(data));
  }, [data, setVisible]);

  return (
    <Beforeunload onBeforeunload={() => trackBeforeunload(acted)}>
      <HvModal
        onClose={handleClose}
        isOpen={visible}
        contentLabel="Lead Sub"
        blockName="c-mdLdSb"
        defaultHeader={false}
      >
        <span
          role="button"
          tabIndex={-1}
          className="c-mdLdSb__close u-p-pointer u-t-nooutline u-t-white"
          onClick={handleClose}
        >
          <span
            className="c-mdLdSb__sym ic-bef ic-site-cross ic-xbld"
            aria-hidden
          />
        </span>

        <div className="c-mdLdSb__header u-t-white">
          <div className="c-mdLdSb__tagline u-t-larger">{tagline}</div>
          <div className="c-mdLdSb__title">{title}</div>
        </div>

        {!isEmpty(image) && (
          <figure className="c-mdLdSb__fig">
            <Imgix
              className="c-mdLdSb__img u-block"
              sizes="45em"
              disableSrcSet
              src={image}
              imgixParams={{ ...commonParams, w: 1000 }}
              htmlAttributes={{
                alt: title,
                draggable: false
              }}
            />
          </figure>
        )}

        <div className="c-mdLdSb__sbsp">
          {success ? (
            <>
              <div className="c-mdLdSb__done-head u-t-bold u-t-white">
                You&apos;re subscribed!
              </div>
              <div className="c-mdLdSb__done-para u-t-larger u-t-white">
                Check your inbox for your welcome gift!
              </div>
            </>
          ) : (
            <>
              <div className="c-mdLdSb__label u-t-larger u-t-white">
                {header}
              </div>
              <SubscribeForm
                blockName="c-mdLdSbForm"
                listId={LEADSUB_MAIL_LIST_ID}
                sourceLabel="leadsub"
                onResponse={handleOnResponse}
              />
            </>
          )}
        </div>
      </HvModal>
    </Beforeunload>
  );
}
